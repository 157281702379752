import React from 'react';
import PrivacySettingsItem from '../privacy-settings-item';
import CommunicationsPreferencesItem from '../communications-preferences-item';
import RecommendedContentItem from '../recommended-content-item';
import ContactUsItem from '../contact-us-item';
import CustomerProfileItem from '../customer-profile-item';
import './toolbar-items.css';
import ActivateProfileItem from './../claiming-process/activate-profile-item';

export default function ToolbarItems ({
    toolbarExpanded
}) {

    let containerClass = 'toolbar-items';
    if(toolbarExpanded){
        containerClass += ' show-items'
    }
    return (
        <div className={containerClass}>
            <PrivacySettingsItem />

            <CommunicationsPreferencesItem />
            <RecommendedContentItem />
            <ContactUsItem />
            <CustomerProfileItem />
            {/* <ClaimProfile/> */}
            <ActivateProfileItem/>
        </div>
    )
}
