export default class ChatService {
    _apiBase = '';
    // _chatType = 'content';

    constructor(apiBaseUrl){
      this._apiBase = apiBaseUrl || 'https://localhost:3000/';
    }

    createFlexChannelChannel = async (channelIdentity, chatType = 'content', chatUserFriendlyName) => {
      const channelSid = await this.getResource(`create-flex-channel`, 'POST', {channelIdentity, chatType, chatUserFriendlyName});
      return channelSid;
    }

    createConversationFlexChannel = async (chatUserFriendlyName) => {
      const conversationChannel = await this.getResource(`create-conversation-flex-channel`, 'POST', {chatUserFriendlyName});
      return conversationChannel;
    }

    addBotToChannel = async (channelSid, chatType = 'content') => {
      const member = await this.getResource(`add-to-channel`, 'POST', {channelSid, chatType});
      return member;
    }

    addChannelWebhookForStudio = async (channelSid, chatType = 'content') => {
      const webhook = await this.getResource('add-to-flow', 'POST', {channelSid, chatType});
      return webhook;
    }

    removeFlowFromChannel = async (channelSid, flowSid, chatType = 'content') => {
      const webhook = await this.getResource('remove-from-flow', 'POST', {channelSid, chatType, pathSid: flowSid});
      return webhook;
    }

    finishFlowExecution = async (channelSid, chatType = 'content') => {
      const webhook = await this.getResource('finish-flow', 'POST', {channelSid, chatType});
      return webhook;
    }

    getToken = async(identity = '') => {
        const tokenData = await this.getResource(`token/${identity}`);
        return tokenData;
    }

    getTokenByType = async(identity = '', type= 'content') => {
      const tokenData = await this.getResource(`token/${identity}/${type}`);
      return tokenData;
    }

    getResource = async (url, method = 'GET', requestBody = null) => {
        let request = {
          method: method,
          headers: {
            "Content-Type": "application/json"
          }
        }
        if(method === 'POST' && requestBody != null){
          request.body = JSON.stringify(requestBody);
        }
        
        const response = await fetch(`${this._apiBase}${url}`, request);
        if(!response.ok){
          throw new Error(`Could not fetch ${url}
          Received: ${response.status}`)
        }
        const body = await response.json();
        return body;
      }

      getMessageChoiceMockup = () => {
        return {
          sid: 'IM70a366246353491',
          type: "combined",
          body: "Thanks for stopping buy! Have you had a chance to check out our <a target='blank' href='https://emergenttravelhealth.com/privacy-policy'>source</a> yet?",
          isBodyHtml: true,
          buttons: [
              {
                label: "Let's check it out",
                value: "Let's check it out",
                type: "message"
            },
            {
              label: "Check source",
              value: "https://emergenttravelhealth.com/privacy-policy",
              type: "link"
          },
          ] 
        }
      }

      getContentChatConfigMockup = (params) => {
        return {
            type:'content',
            modulesLink: 'http://localhost:5000/static/js/2.3ac980f5.chunk.js',
            mainCodeLink: 'http://localhost:5000/static/js/main.a0cd7a12.chunk.js',
            runtimeLink: 'http://localhost:5000/static/js/runtime-main.c7f5b44c.js',
            stylesLink: 'http://localhost:5000/static/css/main.83fcd7ad.chunk.css',
            replaceLinks: [
              {
                  placeholder:'[terms_and_conditions]', title: 'Terms and Conditions', url:'https://www.travelhealthconnect.com/terms/'
              },
              {
                  placeholder:'[privacy_policy]', title: 'Privacy Policy', url:'https://www.travelhealthconnect.com/privacy-policy/'
              }
            ],
            openTimeOut: 5000,
            showChat: false,
            alwaysActiveInput: false,
            showBackButton: true,
        }
      }

      getRegistrationChatConfigMockup = (params) => {
        return {
            type:'registration',
            modulesLink: 'http://localhost:5000/static/js/2.3ac980f5.chunk.js',
            mainCodeLink: 'http://localhost:5000/static/js/main.a0cd7a12.chunk.js',
            runtimeLink: 'http://localhost:5000/static/js/runtime-main.c7f5b44c.js',
            stylesLink: 'http://localhost:5000/static/css/main.83fcd7ad.chunk.css',
            replaceLinks: [
              {
                  placeholder:'[terms_and_conditions]', title: 'Terms and Conditions', url:'https://www.travelhealthconnect.com/terms/'
              },
              {
                  placeholder:'[privacy_policy]', title: 'Privacy Policy', url:'https://www.travelhealthconnect.com/privacy-policy/'
              }
            ],
            openTimeOut: 5000,
            showChat: false,
            alwaysActiveInput: false,
            showBackButton: true,
        }
      }

      getMirfChatConfigMockup = (params) => {
        return {
            type:'mirf',
            modulesLink: 'http://localhost:5000/static/js/2.3ac980f5.chunk.js',
            mainCodeLink: 'http://localhost:5000/static/js/main.a0cd7a12.chunk.js',
            runtimeLink: 'http://localhost:5000/static/js/runtime-main.c7f5b44c.js',
            stylesLink: 'http://localhost:5000/static/css/main.83fcd7ad.chunk.css',
            openTimeOut: 5000,
            showChat: false,
            alwaysActiveInput: false,
            showBackButton: true,
        }
      }

      getConversationalExchangeChatConfigMockup = (params) => {
        return {
            type:'conversationalExchange',
            modulesLink: 'http://localhost:5000/static/js/2.3ac980f5.chunk.js',
            mainCodeLink: 'http://localhost:5000/static/js/main.a0cd7a12.chunk.js',
            runtimeLink: 'http://localhost:5000/static/js/runtime-main.c7f5b44c.js',
            stylesLink: 'http://localhost:5000/static/css/main.83fcd7ad.chunk.css',
            openTimeOut: 5000,
            showChat: false,
            alwaysActiveInput: true,
            showBackButton: false,
            startPhrase: 'system_new_flow',
        }
      }

      getTransactionalExchangeChatConfigMockup = (params) => {
        return {
            type:'transactionalExchange',
            modulesLink: 'http://localhost:5000/static/js/2.3ac980f5.chunk.js',
            mainCodeLink: 'http://localhost:5000/static/js/main.a0cd7a12.chunk.js',
            runtimeLink: 'http://localhost:5000/static/js/runtime-main.c7f5b44c.js',
            stylesLink: 'http://localhost:5000/static/css/main.83fcd7ad.chunk.css',
            openTimeOut: 5000,
            showChat: false,
            alwaysActiveInput: true,
            showBackButton: false,
        }
      }

      getRetailVoucherChatConfigMockup = (params) => {
        return {
            type:'retailVoucher',
            modulesLink: 'http://localhost:5000/static/js/2.3ac980f5.chunk.js',
            mainCodeLink: 'http://localhost:5000/static/js/main.a0cd7a12.chunk.js',
            runtimeLink: 'http://localhost:5000/static/js/runtime-main.c7f5b44c.js',
            stylesLink: 'http://localhost:5000/static/css/main.83fcd7ad.chunk.css',
            openTimeOut: 5000,
            showChat: true,
            alwaysActiveInput: false,
            showBackButton: false,
        }
      }

      getInfectiousDiseaseChatConfigMockup = (params) => {
        return {
            type:'infectiousDisease',
            modulesLink: 'http://localhost:5000/static/js/2.3ac980f5.chunk.js',
            mainCodeLink: 'http://localhost:5000/static/js/main.a0cd7a12.chunk.js',
            runtimeLink: 'http://localhost:5000/static/js/runtime-main.c7f5b44c.js',
            stylesLink: 'http://localhost:5000/static/css/main.83fcd7ad.chunk.css',
            openTimeOut: 5000,
            showChat: false,
            alwaysActiveInput: false,
            showBackButton: true,
        }
      }

      generatePinForChannel = async (channelSid) => {
        const pinCodeData = await this.getResource(`generate-pin-for-channel`, 'POST', {channelSid});
        return pinCodeData;
      }
      
};
