import React from 'react'
import './toolbar-notify.css'

export default function ToolbarNotify({success = true, message, displaying = false}) {
    return (
        <div className={`toolbar-notify ${success?'success': 'error'} ${displaying?'displaying':''}`}>
            <i className='icon'></i>
            <span className='message'>{message}</span>
        </div>        
    )
}
