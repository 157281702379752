import * as csmMenuItems from './../utils/csm-menu-items';

export function processCustomerData(data){
    if(data !== undefined && data !== null){
        processCustomerCommunicationsPreferences(data)
    }
    return data;
}

export const processCustomerCommunicationsPreferences = (data) => {

    if(!data.communicationPreferences){
        data.communicationPreferences = getDefaultCustomerCommunicationsPreferences();
    }

}

export const getDefaultCustomerCommunicationsPreferences = () => {
    return [
        {id:'email', title:'Email', active: true, order: 1},
        {id:'phone', title:'Voice/Phone', active: true, order: 2},
        {id:'sms', title:'SMS/Text', active: true, order: 3},
        {id:'address', title:'Address', active: true, order: 4},
    ] 
}

export const getMenuItems = (config) => {
    const menuItems = [
        {id: 3, title: 'Privacy Settings', img: 'images/settings-icon.svg', alias: csmMenuItems.CSM_MENU_ITEM_PRIVACY_SETTINGS},
        // {id: 3, title: 'Recommended Content', img: 'images/recommended-content-icon.png', alias: csmMenuItems.CSM_MENU_ITEM_RECOMMENDED_CONTENT},
        {id: 4, title: 'Contact Us', img: 'images/contact-icon.svg', alias: csmMenuItems.CSM_MENU_ITEM_CONTACT_US},
    ]
    
    if(config.menuType ==='customer'){
        if(config.userId != null){
            menuItems.push({id: 1, title: 'Your Profile', img: 'images/profile-icon.svg', alias: csmMenuItems.CSM_MENU_ITEM_CUSTOMER_PROFILE, hideWhenActive: [csmMenuItems.CSM_MENU_ITEM_CLAIM_PROFILE, csmMenuItems.CSM_MENU_ITEM_CUSTOMER_PROFILE, csmMenuItems.CSM_MENU_ITEM_ACTIVATE_PROFILE]})
        }
        else{
            // menuItems.push({id: 6, title: 'Claim Your Customer Profile', img: 'images/customer-profile-icon.png', alias: csmMenuItems.CSM_MENU_ITEM_CLAIM_PROFILE, hideWhenActive: [csmMenuItems.CSM_MENU_ITEM_CLAIM_PROFILE, csmMenuItems.CSM_MENU_ITEM_CUSTOMER_PROFILE]})
            menuItems.push({id: 1, title: 'Activate your profile', img: 'images/profile-icon.svg', alias: csmMenuItems.CSM_MENU_ITEM_ACTIVATE_PROFILE, hideWhenActive: [csmMenuItems.CSM_MENU_ITEM_CLAIM_PROFILE, csmMenuItems.CSM_MENU_ITEM_CUSTOMER_PROFILE, csmMenuItems.CSM_MENU_ITEM_ACTIVATE_PROFILE]})
        }
    }

    if(config.userCommunicationPreferenceEmail != null){        
        menuItems.push({id: 2, title: 'Communications Preferences', img: 'images/communications-icon.svg', alias: csmMenuItems.CSM_MENU_ITEM_COMMUNICATIONS_PREFERENCES})
    }

    return menuItems.sort((firstItem, secondItem) => firstItem.id - secondItem.id);
}

export const millisecondsToHmsDisplay = (d) => {
  d = Number(d);

  var dSeconds = d/1000;

  var h = Math.floor(dSeconds / 3600);
  var m = Math.floor(dSeconds % 3600 / 60);
  var s = Math.floor(dSeconds % 3600 % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay;
}

export const generateUniqueId = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 export const download = (filename, link) => {
    var element = document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('download', filename);
    element.setAttribute('target', '_blank');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}