import React, {useState} from 'react'
import Spinner from '../spinner'

const withSpinner = (spinnerWidth = '200px', spinnerHeight= '200px', paddingTop = '0px') => (View) => {

    return (props) => {
        const [loading, setLoading] = useState(false);

        return(
            <div className='with-sp' style={{position:'relative'}}>
                {loading && <Spinner width={spinnerWidth} height={spinnerHeight} paddingTop={paddingTop}/>}
                <View {...props} setLoading={setLoading}/>
            </div>
        )
    }
}

export default withSpinner;