import React, {useState, useContext} from 'react'
import { useFormik } from 'formik';
import {withSpinner} from '../../../hoc-helpers';
import Select from 'react-select';
import { SPECIALITIES } from '../../../../config';

import '../../customer-profile-item/customer-profile-item.css'
import '../../../../styles/form.css'
import { claimValidator, validateNpiSearch } from './../../../../utils/validators';
import NpiSearchResults from '../../claim-profile-item/npi-search-results';
import AppContext from './../../../../context/AppContext';
import withNotify from './../../../hoc-helpers/with-notify';

function ClaimProfileForm({setLoading, onClaimSuccess, showNotify}) {

    const {customerCenterService } = useContext(AppContext)
    const [showNpiSearchResult, setShowNpiSearchResult] = useState(false);
    const [searchedCustomer, setSearchedCustomer] = useState(null)

    const formik = useFormik({
        initialValues: {
            npi: '',
            email: '',
            first_name: '',
            last_name: '',
            zip: '',
            speciality: '',
            opt_to_email: false,
            opt_to_sms: false,
            agree_terms: false
        },
        validate:(values) => claimValidator(values),
        onSubmit: async (values, { setSubmitting }) => {
            console.log(values);    

            setLoading(true);
            let claimedSuccessfully = false;
            
            try{
                const response = await customerCenterService.claimProfile(values);

                if(response.success){
                    claimedSuccessfully = true;
                    onClaimSuccess(response.customer);
                }
                else{
                    showNotify(false, response.message)
                }
            }
            catch(err){
                showNotify(false, "Sorry, there are some troubles while claiming. Please, try again later.");
                console.log(err);
            }
            
            if(!claimedSuccessfully){
                setSubmitting(false);
                setLoading(false);
            }
            
        },
    });

    const onSpecialityChange = (option) => {
        formik.setFieldValue('speciality', option.value)
    }

    const onNpiSearchClick = (e) => {
        e.preventDefault();
        setShowNpiSearchResult(false)
        const valid = validateNpiSearch(formik);
        
        if(valid){
            setLoading(true);
            customerCenterService
                .getNpiSearch(formik.values.first_name, formik.values.last_name, formik.values.email, formik.values.zip)
                .then(customer => {
                    console.log(customer);
                    setSearchedCustomer(customer)
                    setShowNpiSearchResult(true);
                })
                .catch(err =>{
                    showNotify(false, "Sorry, there are some troubles with server. Please, try again later.")
                    console.log(err)
                })
                .finally(()=>{
                    setLoading(false);
                })
        }
        
    }

    const onChooseCustomerFromSearch = (npi) => {
        console.log('npi', npi)
        if(npi){
            formik.setFieldValue('npi', npi)
        }
        
        setShowNpiSearchResult(false)
    }

    const form = () => {
        const {errors, touched, values, handleChange, handleBlur} = formik;
        return(
            <>
            <div className="title" style={{marginBottom:'15px'}}>
                Claim your new profile and get access to our features!
            </div>
                <form onSubmit={formik.handleSubmit}>
                    <InputContainer field='npi' formik={formik} title="NPI number">
                        <div className='widget-form-input-wrp'>
                            <input
                                className="widget-form-input"
                                type="text"
                                name="npi"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.npi}
                                maxLength={40}
                            />     
                        </div>   
                        <span className="widget-form-error-text">{errors.npi && touched.npi && errors.npi}</span>
                        <button className="widget-form-link" onClick={onNpiSearchClick}>Lookup your NPI number</button> 
                    </InputContainer>

                    {showNpiSearchResult && <NpiSearchResults customer={searchedCustomer} onChooseCustomer={onChooseCustomerFromSearch}/>}

                    <InputContainer field='first_name' formik={formik} title="First Name"/>
                    <InputContainer field='last_name' formik={formik} title="Last Name"/>
                    <InputContainer field='email' formik={formik} title="Email"/>
                    <InputContainer field='zip' formik={formik} title="ZIP Code"/>
                    <InputContainer field='speciality' formik={formik} title="Speciality">
                        <Select
                            name="speciality"
                            options={SPECIALITIES}
                            menuPlacement='top'
                            onChange={onSpecialityChange}
                            onBlur={formik.handleBlur}
                            className="widget-form-select"
                            classNamePrefix="widget-form-select"
                            placeholder=""
                        />
                        <span className="widget-form-error-text">{formik.errors.speciality && formik.touched.speciality && formik.errors.speciality}</span>                                     
                    </InputContainer>
                    <InputContainer field='opt_to_email' formik={formik} validate={false}>
                        <label className='widget-form-checkbox-block'>
                            <input                               
                                type="checkbox"                                                                
                                name="opt_to_email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.opt_to_email}
                            >
                            </input>
                            <span className='widget-form-checkbox-styled'></span>                          
                            <span className='widget-form-checkbox-title'>OPT IN to e-mail communications</span>
                        </label>
                        <span className="widget-form-error-text">{formik.errors.opt_to_email && formik.touched.opt_to_email && formik.errors.opt_to_email}</span>
                    </InputContainer>
                    <InputContainer field='opt_to_sms' formik={formik} validate={false}>
                        <label className='widget-form-checkbox-block'>
                            <input                               
                                type="checkbox"                                                                
                                name="opt_to_sms"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.opt_to_sms}
                            >
                            </input>
                            <span className='widget-form-checkbox-styled'></span>                          
                            <span className='widget-form-checkbox-title'>OPT IN to SMS communications</span>
                        </label>
                        <span className="widget-form-error-text">{formik.errors.opt_to_sms && formik.touched.opt_to_sms && formik.errors.opt_to_sms}</span>
                    </InputContainer>

                    <div className="widget-form-group">                            
                        <div className="widget-form-tems widget-form-scroll-styled">
                            Terms & Conditions. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elt, Quisque ullamcorper
                            ictum imperciet. Ut laoreet, enim ut convallis
                            consectetur, orci neque blandit nibh, ut blandit
                            lacus felis sed risus.
                            <br/>
                            <br/>
                            Lorem ipsum dolor sit amet,
                            consectetur adipiscing elt, Quisque ullamcorper
                            ictum imperciet. Ut laoreet, enim ut convallis
                            consectetur, orci neque blandit nibh, ut blandit
                            lacus felis sed risus.
                        </div>
                    </div>
                    <InputContainer field='agree_terms' formik={formik}>
                        <label className='widget-form-checkbox-block'>
                            <input                               
                                type="checkbox"                                                                
                                name="agree_terms"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.agree_terms}           
                            >
                            </input>
                            <span className='widget-form-checkbox-styled'></span>                          
                            <span className='widget-form-checkbox-title'>I agree to these terms & conditions</span>
                        </label>
                        <span className="widget-form-error-text">{formik.errors.agree_terms && formik.touched.agree_terms && formik.errors.agree_terms}</span>
                    </InputContainer>

                    <div className="toolbar-form-group">
                        <button className="std-button submit-btn" type="submit">
                            Claim profile
                        </button>
                    </div>
                    
                </form>
            </>
        )
    }

    return (
        <div className="claim-profile-form form-canvas active-form">
            {form()}
        </div>
    )
}

export default withNotify()(withSpinner('100px', '100px', '50px')(ClaimProfileForm));

export const InputContainer = ({field, formik, children, title, validate = true, show = true, titleAdditional = null,inputAdditional = null}) => {
    const content = () => {
        if(children)
            return children
        return (
            <>
                <div className='widget-form-input-wrp'>
                    <input
                        className="widget-form-input"
                        type="text"
                        name={field}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values[field]}
                        maxLength={40}/> 
                </div>
                <span className="widget-form-error-text text-pl">{formik.errors[field] && formik.touched[field] && formik.errors[field]}</span>
                {inputAdditional && inputAdditional}
            </>   
        )

    }
    return(
        <div className={`widget-form-group 
        ${show === false ? 'hidden-widget' : ''}
        ${validate && !formik.errors[field] && formik.touched[field] ? 'widget-form-group-success' : ''}
        ${validate && formik.errors[field] && formik.touched[field] && formik.errors[field] ? 'widget-form-group-error' : ''}`}>
            <span className='widget-form-label text-pl'><b>{title}</b> {titleAdditional ? titleAdditional : ''}</span>
            {content()}
        </div>
    )
}
