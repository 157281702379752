import React from 'react';
import ToolbarItemHeader from '../toolbar-item-header';
import { withToolbarSwitcher } from '../../hoc-helpers';

import './recommended-content-item.css';

function RecommendedContentItem ({
    activeToolbarMenuItem,
    switchActiveToolbarItem,
}) {

    let componentClassName = 'recommended-content-item toolbar-item';
    if(activeToolbarMenuItem === 'recommended-content'){
        componentClassName += ' active';
    }
    return (
        <div className={componentClassName}>
            <ToolbarItemHeader image="images/recommended-content-icon.png" onBackButtonClick={switchActiveToolbarItem}>
                Recommended Content
            </ToolbarItemHeader>
            <div className="item-main">

            </div>
        </div>
    )
}

export default withToolbarSwitcher()(RecommendedContentItem);
