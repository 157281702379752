export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const CHANNEL_SID_LENGTH = process.env.REACT_APP_CHANNEL_SID_LENGTH;
export const IMG_HOSTING = process.env.REACT_APP_IMG_HOSTING;
export const COOKIE_POLICY_EXPIRE_DAYS = process.env.REACT_APP_COOKIE_POLICY_EXPIRE_DAYS;
export const AUTH_MAIN_DOMAIN = process.env.REACT_APP_AUTH_MAIN_DOMAIN;
export const DEBUG_MESSAGES = process.env.REACT_APP_DEBUG_MESSAGES;
export const IP_CALL_NUMBER = process.env.REACT_APP_IP_CALL_NUMBER;

export const PRC_CODE = 'PP-TH-US-00087 | February 2023';

export const SPECIALITIES = [
    { value: 'doctor', label: 'Doctor' },
    { value: 'nurse', label: 'Nurse' },
    { value: 'pharm', label: 'PharmD' },
    { value: 'pharmacy_technitian', label: 'Pharmacy Technitian' },
    { value: 'travel', label: 'Travel Specialist' },
]
export const COMMUNICATION_PREFERENCES_CUSTOMER = [
    {
        value: 'emergenttravelhealth.com', 
        label: 'emergenttravelhealth.com', 
        audiences:[            
            {id: 'a0203d4abf', listTitle: "Customer marketing email list", subscribeLink: "http://eepurl.com/gWOeJj"}
        ]
    },
    {
        value: 'thereisnosafezone.com', 
        label: 'thereisnosafezone.com', 
        audiences:[
            {id: 'a0203d4abf', listTitle: "Customer marketing email list", subscribeLink: "http://eepurl.com/gWOeJj"}
        ]
    },
    {
        value: 'vivotif.com', 
        label: 'vivotif.com',
        audiences:[
            {id: 'a0203d4abf', listTitle: "Customer marketing email list", subscribeLink: "http://eepurl.com/gWOeJj"}
        ]
    },
    {
        value: 'vaxchora.com', 
        label: 'vaxchora.com',
        audiences:[
            {id: 'a0203d4abf', listTitle: "Customer marketing email list", subscribeLink: "http://eepurl.com/gWOeJj"}
        ]
    }
]
export const COMMUNICATION_PREFERENCES_CONSUMER = [
    {
        value: 'travelhealthconnect.com', 
        label: 'travelhealthconnect.com', 
        audiences:[            
            {id: 'a0203d4abf', listTitle: "Consumer marketing email list", subscribeLink: "http://eepurl.com/gWOeJj"}
        ]
    },
    {
        value: 'chikungunya.com', 
        label: 'chikungunya.com', 
        audiences:[
            {id: 'a0203d4abf', listTitle: "Consumer marketing email list", subscribeLink: "http://eepurl.com/gWOeJj"}
        ]
    }
]