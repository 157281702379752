import React, {useContext, useState, useEffect} from 'react';
import { withToolbarSwitcher } from '../../hoc-helpers';
import './contact-us-item.css';
import { AppContext } from '../../../context';
import ToolbarItem from './../../cc-components/toolbar-item';
import { CONTACT_STAGE_MAIN_MENU, CONTACT_STAGE_REPRESENTATIVE_TYPES, CONTACT_STAGE_WEB_CHAT } from './../../../utils/contact-stages';
import ContactUsMenu from './contact-us-menu';
import ContactChat from './contact-chat';
import RepresentativeSelection from './representative-selection';
import { OPEN_CSM_CONTACT_US, OPEN_CSM_CHAT_WEB, OPEN_CSM_SEND_EMAIL, OPEN_CSM_CHAT_ORDER_PRODUCT } from './../../../utils/csm-interactive';
import {CSM_MENU_ITEM_CONTACT_US} from './../../../utils/csm-menu-items';
import { observer } from 'mobx-react-lite';

const CUSTOMER_CARE_EMAIL = 'customercare@mail2.emergenttravelhealth.com';

function ContactUsItem ({
    activeToolbarMenuItem,
    switchActiveToolbarItem,
}) {
        
    const { chatStore, privacyPolicyStore, uiStore, customerStore } = useContext(AppContext);
    const [contactType, setContactType] = useState('email')
    const [chatType, setChatType] = useState('web');
    
    const chekStatusAndCallCsmAction = (csmAction) => {
        if(privacyPolicyStore.privacyPolicyStatus === 'hidden'){
            csmAction()
        }
        else if(privacyPolicyStore.privacyPolicyStatus === 'declined'){
          privacyPolicyStore.setPrivacyPolicyStatus(undefined);
        }
        else{
            uiStore.shakeCookiesContainer();
        }
    }

    useEffect(() => {
      
        document.addEventListener('click',function(e){

            if(e.target && e.target.classList.contains(OPEN_CSM_CONTACT_US)){
                chekStatusAndCallCsmAction(() => {
                    e.preventDefault()
                    uiStore.setToolbarOpened(true);
                    switchActiveToolbarItem(CSM_MENU_ITEM_CONTACT_US);
                })
            }
            else if(e.target && e.target.classList.contains(OPEN_CSM_SEND_EMAIL)){
                chekStatusAndCallCsmAction(() => {
                    e.preventDefault()
                    uiStore.setToolbarOpened(true);
                    switchActiveToolbarItem(CSM_MENU_ITEM_CONTACT_US);
                    openRepsSelection('email')
                })
            }
            else if(e.target && e.target.classList.contains(OPEN_CSM_CHAT_WEB)){
                chekStatusAndCallCsmAction(() => {
                    e.preventDefault()
                    uiStore.setToolbarOpened(true);
                    switchActiveToolbarItem(CSM_MENU_ITEM_CONTACT_US);
                    openRepsSelection('chat','web')
                })
            }
            else if(e.target && e.target.classList.contains(OPEN_CSM_CHAT_ORDER_PRODUCT)){
                chekStatusAndCallCsmAction(() => {
                    e.preventDefault()
                    uiStore.setToolbarOpened(true);
                    switchActiveToolbarItem(CSM_MENU_ITEM_CONTACT_US);
                    orderProduct(e.target.dataset.productName);
                })
            }
        })
    }, [])

    const openMslChat = () => {
        window.location.href = "mailto:ethemailaddress@email.com";
    }

    const onChatStart = (repsType = 'medical') => {
        console.log('reps type', repsType)
        chatStore.setRepresentativeType(repsType);
        chatStore.setContactStage(CONTACT_STAGE_WEB_CHAT)
    }

    const onStartOverChat = (chatType) => {
        chatStore.setActiveChatSession(null);
        chatStore.setActiveSessionRepresentativeType(null);
        const isUserAuthorized = customerStore.customerData.userId !== null;
        if(isUserAuthorized){
            openRepsSelection('chat', chatType);
        }
        else{
            chatStore.setContactStage(CONTACT_STAGE_MAIN_MENU)
        }
        
    } 

    let onBackButtonClick = switchActiveToolbarItem;
    if(chatStore.contactStage === CONTACT_STAGE_WEB_CHAT || chatStore.contactStage === CONTACT_STAGE_REPRESENTATIVE_TYPES){
        onBackButtonClick = () => chatStore.setContactStage(CONTACT_STAGE_MAIN_MENU)
    }

    let componentClassName = 'contact-us-item toolbar-item';
    if(activeToolbarMenuItem === CSM_MENU_ITEM_CONTACT_US){
        componentClassName += ' active';
    }

    const openRepsSelection = (contactType, chatType = 'web') => {
        console.log('open reps', contactType, chatType);
        //if we have already started any chat session
        if(contactType !== 'email' && chatStore.activeChatSession !== null){
            setChatType(chatStore.activeChatSession)
            chatStore.setContactStage(CONTACT_STAGE_WEB_CHAT)
        }
        else {
            setContactType(contactType);
            setChatType(chatType)
            const isUserAuthorized = customerStore.customerData.userId !== null;

            if(contactType === 'email' && !isUserAuthorized){
                window.location.href = "mailto:"+ CUSTOMER_CARE_EMAIL;                
            }
            else{
                chatStore.setContactStage(CONTACT_STAGE_REPRESENTATIVE_TYPES)
            }            
        }

    }

    const orderProduct = (productName) => {
        chatStore.onOrderProduct(productName);
    }
    
    return (
        <div className={componentClassName}>
            <ToolbarItem itemId='csm-contact-us' image="images/contact-icon.png" onBackButtonClick={onBackButtonClick} title={'Contact Us'}>
            
                <ContactUsMenu
                    openRepsSelection={openRepsSelection}
                    openMslChat={openMslChat}/>

                <RepresentativeSelection
                    contactType={contactType}
                    chatType={chatType}
                    onChatStart={onChatStart}/>

                <ContactChat
                    chatType={chatType}
                    onStartOverChat={onStartOverChat}/>

            </ToolbarItem>
        </div>
    )
}

export default withToolbarSwitcher()(observer(ContactUsItem))
