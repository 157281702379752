import React, { Component } from 'react';
import './error-indicator.css';

export default class ErrorIndicator extends Component {
    render() {
        return (
            <div className="error-indicator">
                <span className="indicator-text">
                    Sorry. It seems we have some problems with this functionality. <br/> Please, try again later.
                </span>
            </div>
        )
    }
}
