import { observer } from 'mobx-react-lite';
import React, {useContext} from 'react';
import { AppContext } from '../../../context';
import { CONTACT_STAGE_REPRESENTATIVE_TYPES } from '../../../utils/contact-stages';
import PrcFooter from '../prc-footer';

const RepresentativeSelection = ({ 
    contactType,
    chatType,
    onChatStart,
}) => {

    const { customerStore, chatStore } = useContext(AppContext);

    const title = contactType === 'email' 
                    ? 'Send Email' 
                    : (chatType === 'web' ? "Live Chat" : "Call Us");

    
    const isUserAuthorized = customerStore.customerData.userId !== null;

    let elements;
    if(contactType === 'email'){
        elements = <>
            <a href='mailto:engagement@mail2.emergenttravelhealth.com' className='reps-option std-button'>Commercial</a>
            <a href='mailto:medicalinformation@ebsi.com' className='reps-option std-button'>Medical</a>
            <a href='mailto:customercare@mail2.emergenttravelhealth.com' className='reps-option std-button'>Customer Care</a>
        </>
    }
    else{
        elements = <>
            {isUserAuthorized && <button className='reps-option std-button' onClick={() => onChatStart('commercial')}>Commercial</button>}
            {isUserAuthorized && <button className='reps-option std-button' onClick={() => onChatStart('medical')}>Medical</button>}
            <button className='reps-option std-button' onClick={() => onChatStart('customerCare')}>Customer Care</button>
        </>
    }

    return (
        <div className={`contact-us-stage csm-stage contact-us-representatives ${chatStore.contactStage === CONTACT_STAGE_REPRESENTATIVE_TYPES ? 'active-stage' : 'inactive-stage'}`}>
            <div className='stage-title'>{title}</div>
            <div className='stage-body body-with-title body-with-prc-footer'>
                <div className='reps-title'>
                    Help us make sure you reach the right person. Do you need help with:
                </div>
                <div className='reps-list'>
                    {elements}
                </div>
                <PrcFooter absolute={true}/>
            </div>
        </div>
        
    )
}

export default observer(RepresentativeSelection)
