import React from 'react'
import ButtonsLIst from '../buttons-list';

function ChatMessage({
    message,
    onButtonClick
}) {

    const { 
        sid,
        body,
        type,
        isBodyHtml = false,
        buttons = null,
        options = null,
        isMulti = false,
        onMessageLinkClick,
    } = message;

    const msgText = !isBodyHtml 
                    ? <span className="msg-text">{body}</span> 
                    : <span className="msg-text" dangerouslySetInnerHTML={{__html: body}}></span>
            
    const typeClass= (type !== undefined && type === 'voice_message') ? 'voice-bubble':'';
            
    return (
        <div className="chat-message ">
            <div className={`message-bubble ${typeClass}`}>
                {msgText}
            </div>
            {buttons != null ? <ButtonsLIst buttons={buttons} messageSid={sid} onMessageLinkClick={onMessageLinkClick} onButtonClick={onButtonClick}/> : null}
            
        </div>
    )
}

export default ChatMessage
