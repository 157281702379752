import * as config from '../../../config';
import { CookiesDefault, CookiesAgreed } from '../cookies-templates';
import './cookies-container.css';
import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite';
import {AppContext} from '../../../context';

function CookiesContainer() {

    const{privacyPolicyStore, uiStore} = useContext(AppContext);

    const onDeclineUserPrivacyPolicy = () => {
        const allDeclined = privacyPolicyStore.customerPrivacyPolicyPreferences.map(preference => {
            preference.value = false;
            return preference;
        })
        privacyPolicyStore.setConsentManagerPreferences(allDeclined)
        privacyPolicyStore.setPrivacyPolicyStatus('declined');
    }

    const onAgreePrivacyPolicy = () => {
        const allAgreed = privacyPolicyStore.customerPrivacyPolicyPreferences.map(preference => {
            preference.value = true;
            return preference;
        })
        privacyPolicyStore.setConsentManagerPreferences(allAgreed)

        var key = window.consentManagerConfig().writeKey;
        window.analytics.load?.(key);

        privacyPolicyStore.setPrivacyPolicyStatus('agreed');
    }

    let containerClass = "cookies-container";
    if(privacyPolicyStore.privacyPolicyStatus !== 'declined' && privacyPolicyStore.privacyPolicyStatus !== 'hidden'){
        containerClass += ' container-open'
    }
    if(uiStore.isCookiesContainerShaken){
        containerClass += ' shaked';
    }

    const closeButton = () => { 
        return (
            <span className="close-widget" onClick={() => privacyPolicyStore.hideCookiesPolicy(false)}>
                <img src={`${config.IMG_HOSTING}/images/close-icon.svg`} alt="close"/>
            </span>
        );
     }

    return (
        <div className={containerClass}>           
            <div className="cookies-header">
                <div className="cookies-header-left-container">
                    <span className="cookies-header-title font-bold">Cookies</span>
                </div>
                <div className="cookies-header-right-container">                    
                    {privacyPolicyStore.privacyPolicyStatus === 'agreed' && closeButton()}
                </div>
            </div>
            <div className='cookies-body'>
                {privacyPolicyStore.privacyPolicyStatus === 'agreed'
                    ? <CookiesAgreed onHideCookiesPolicy={(expanded) => privacyPolicyStore.hideCookiesPolicy(expanded)}/> 
                    : <CookiesDefault onDeclineUserPrivacyPolicy={onDeclineUserPrivacyPolicy} 
                                    onAgreePrivacyPolicy = {onAgreePrivacyPolicy}/>}
            </div>
        </div>
    )
}

export default observer(CookiesContainer)
