import React from 'react';
import ReactDOM from 'react-dom';
import * as config from './config';
import App from './components/app/App';
import AppContext from './context/AppContext';
import CustomerCenterService from './services/CustomerCenterService';
import RootStore from './store/root-store';
import ChatService from './services/ChatService';

const customerCenterService = new CustomerCenterService(config.API_BASE_URL);
const chatService = new ChatService(config.API_BASE_URL);
const root = new RootStore();

ReactDOM.render(
  <React.StrictMode>
    <AppContext.Provider value={{
      customerCenterService: customerCenterService,
      chatService: chatService,
      root: root,
      privacyPolicyStore: root.privacyPolicyStore,
      uiStore: root.uiStore,
      customerStore: root.customerStore,
      chatStore: root.chatStore,
    }}>
      <App/>
    </AppContext.Provider>
  </React.StrictMode>,
  document.getElementById('ebsi-customer-center')
);

