import React, { useContext } from 'react'
import { AppContext } from '../../context';
import { observer } from 'mobx-react-lite';

const withToolbarSwitcher = () => (View) => {

    return observer((props) => {

        const{uiStore} = useContext(AppContext);

        const switchActiveToolbarItem = (item) => {
            uiStore.setActiveToolbarMenuItem(item);
            uiStore.switchToolbar(true);
        }

        return <View 
                {...props} 
                activeToolbarMenuItem={uiStore.activeToolbarMenuItem}
                switchActiveToolbarItem={switchActiveToolbarItem}
            />
    })
}

export default withToolbarSwitcher