import React, {useContext} from 'react'
import { AppContext } from '../../context';

function WelcomeTitle() {    

    const{customerStore} = useContext(AppContext);
    
    const getDailyGreetings = () => { 
        var today = new Date()
        var curHr = today.getHours()

        if (curHr >= 5 && curHr <= 11) {
            return 'morning';
        } else if (curHr >= 12 && curHr <= 16) {
            return 'afternoon';
        } else {
            return 'evening';
        }
     }

    let label = "Welcome to the Emergent Customer Success Menu";
    let description ="Please choose from the following options:";

    if(customerStore.customerData.userId != null){
            label = `Good ${getDailyGreetings()}, ${customerStore.customerData.first_name} ${customerStore.customerData.last_name}.`;
            description ="How can we help you today?";
    }



    return (
    <div className='welcome-title'>                     
        <div className='welcome-label font-bold'>
            {label}
        </div>
        <div className='welcome-description'>                        
            {description}
        </div> 
    </div>
    )
}

export default WelcomeTitle