import React from 'react';
import ToolbarItemHeader from '../customer-toolbar/toolbar-item-header';
import ErrorBoundry from '../errors/error-boundry';
import CsmModal from './../csm-modal';

export default function ToolbarItem ({ 
    image,
    onBackButtonClick, 
    title,
    children,
    showModal = false,
    modalContent = null,
    setShowModal,
    itemId,
}) {
    return (
        <>
            <ToolbarItemHeader image={image} onBackButtonClick={onBackButtonClick}>
                {title}
            </ToolbarItemHeader>
            <ErrorBoundry>
                <div className='item-inner'>
                    <div id={itemId} className="item-main">
                        {children}
                    </div>
                    <CsmModal showModal={showModal} modalContent={modalContent} setShowModal={setShowModal}/>      
                </div>              
            </ErrorBoundry>
        </>
    )
}
