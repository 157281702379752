import React, {useState} from 'react'
import './claim-profile-item.css'

function NpiSearchResults({
    customer,
    onChooseCustomer
}) {
    const [showSearchNotify, setShowSearchNotify] = useState(false)

    if(!customer){
        return <div className="npi-search-results widget-form-group">No customers with such data</div>
    }
    else if(showSearchNotify){
        return <div className="npi-search-results widget-form-group">Provide more details such as email or zip code to make the search more efficient.</div>
    }

    return (
        <div className="widget-form-group npi-search-results">
            <span className='search-result-title'>Here is the profiles we found for your request</span>
            <div className='search-result-text'>{`${customer.first_name} ${customer.last_name}, NPI: ${customer.npi ?? 'Unknown' }`}</div>
            <span className='search-result-queston'>Is this you?</span>
            <div className="search-result-butons">
                <button className="std-button search-result-btn" type="button" onClick={()=>setShowSearchNotify(true)}>No</button>
                <button className="std-button search-result-btn" type="button" onClick={() => onChooseCustomer(customer.npi)}>Yes</button>
            </div>
        </div>
    )
}

export default NpiSearchResults;