import React from 'react'

function SearchFormFooter() {
    return (
        <div className='search-from-footer'>
            <div className='privacy-policy-notify border-btm'>
                How do we use your information?
                <br/><br/>
                Please refer to our <a href={window.ebsiChatConfig.privacyPolicyLink} className='std-link' target='_blank' rel="noreferrer">Privacy Policy</a>.
            </div>
            <div className="toolbar-form-group form-submit-container">
                <button className="std-button submit-btn" type="submit">
                    Continue
                </button>
            </div>
        </div>
    )
}

export default SearchFormFooter
