import React from 'react';
import './toolbar-icons.css';
import * as config from '../../../config';

function ExpandToolbarIcon({onIconClick}) {
    return (
        <span className="expand-toolbar-icon switch-tb-icon csm-header-icon" onClick={() => onIconClick(true)}>
            <img  src={`${config.IMG_HOSTING}/images/back-icon.svg`} alt="back"></img>
        </span>
    )
}

function CollapseToolbarIcon({onIconClick}) {
    return (
        <span className="collapse-toolbar-icon switch-tb-icon csm-header-icon" onClick={() => onIconClick(false)}>
            <img  src={`${config.IMG_HOSTING}/images/minimize-icon.svg`} alt="minimize"></img>            
        </span>
    )
}

export {
    ExpandToolbarIcon,
    CollapseToolbarIcon,
}
