import React from 'react'
import PrcFooter from '../prc-footer'

function PrivacySettingsList({
    privacyPreferences,
    onTogglePreferences,
}) {

    const preferences = privacyPreferences.map(preference => {
        return(
            <div className="preference-item" key={preference.id}>
                <div className='preference-header'>
                    <label className="switch">
                        <span className='preference-item-title'>{preference.title}</span>
                        <span className='check-block'>
                            <span className='checkbox-title'>{preference.value ? 'Yes' : 'No'}</span>
                            <input type="checkbox" onChange={() => onTogglePreferences(preference.id)} checked={preference.value} data-test={preference.value}/>
                            <span className={`slider round ${!preference.value ? 'inactive' : ''}`}></span>
                        </span>                                            
                    </label>
                </div>
                <div className="preference-description">{preference.description}</div>
            </div>
        )
    })

    return (
        <div className='privacy-settings-list'>
            <div className='stage-title'>Manage Privacy Settings</div>
            <div className='stage-body body-with-title body-with-prc-footer'>
                <div className='privacy-settings-description'>
                    <p>
                        We use data collected by cookies and
                        JavaScript libraries to improve your
                        browsing experience, analyze site traffic,
                        deliver personalized advertisements, and
                        increase the overall performance of our
                        sites.
                    </p>
                    <p>
                        You can manage your permissions for how
                        we may use the data we collect using the
                        settings below. These settings describe the
                        current permissions we have enabled for
                        you as a user of one of our sites.
                    </p>
                    <p>
                        For more information about how we
                        handle personal information that you
                        provide to us, you may access this site’s
                        Privacy Policy at any time.
                    </p>
                </div>
                <div className="preference-list">
                    {preferences}
                </div>
                <PrcFooter absolute={true}/>
            </div>
            
        </div>
    )
}

export default PrivacySettingsList
