import React, {useContext, useRef, useEffect} from 'react'
import {AppContext} from '../../context'
import { observer } from 'mobx-react-lite';
import * as config from '../../config';

import './widget-button.css';

function WidgetButton() {

    const{privacyPolicyStore, uiStore} = useContext(AppContext);
    const firstTimeShowButton = useRef(false)
    

    const toggleToolbar = () => {
        uiStore.setToolbarOpened(!uiStore.toolbarOpened);
    }

    let containerClass = "widget-button";

    let showPulse = false;

    if(privacyPolicyStore.privacyPolicyStatus === 'hidden'){
        containerClass += ' widget-button-visible'

        if(privacyPolicyStore.previousCustomerPrivacyPolicyStatus != 'undefined'){
            showPulse = true;
        }
    }
    return (
        <div className={containerClass}>
            <button
            onClick={()=>toggleToolbar()} 
            className={`menu-control-btn ${showPulse ?'pulse-attention':''} ${uiStore.widgetButtonIsPulse &&  !uiStore.toolbarOpened?'pulse-red':''}`}
            style={{backgroundImage: `url(${config.IMG_HOSTING}/images/info-icon.svg)`}}
            ></button>
        </div>
    )
}

export default observer(WidgetButton)
