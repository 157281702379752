import React from 'react';
import * as config from '../../../config';
import './toolbar-menu.css';
import { withToolbarSwitcher } from '../../hoc-helpers';
import { observer } from 'mobx-react-lite';
import { ToolbarItem } from '../../cc-components';
import WelcomeTitle from '../../cc-components/welcome-title';
import PrcFooter from '../prc-footer';

function ToolbarMenu ({ switchActiveToolbarItem: onMenuItemClick, activeToolbarMenuItem, menuItems }){

    let buttons = [];
    menuItems.forEach(item => {
        let showButton = activeToolbarMenuItem !== item.alias;
        if(item.hideWhenActive?.length > 0){
            showButton = !item.hideWhenActive.includes(activeToolbarMenuItem)
            console.log('show button', showButton);
        }
        if(showButton){
            const button = (
                <button className={`tb-menu-item font-bold menu-item-${item.alias}`} onClick={() => onMenuItemClick(item.alias)} key={item.alias}>
                        <span className='tb-menu-icon-wrapper'> 
                            <img className="tb-menu-icon" src={`${config.IMG_HOSTING}/${item.img}`} alt={item.title}></img>
                        </span>                       
                        <span className="tb-item-text">{item.title}</span>
                    </button>
                )
            buttons.push(button);
        }
    })

    let containerClass = 'toolbar-menu';
    if(activeToolbarMenuItem != null){
        containerClass += ' with-active-item';
    }

    return (
        <div className={containerClass}>
            
            <ToolbarItem title="Welcome"> 
                <div className='stage-body body-with-prc-footer'>
                    <WelcomeTitle/>    
                    {buttons}
                    <PrcFooter absolute={true}/>
                </div>             
            </ToolbarItem>
        </div>
    )
}
// export default ToolbarMenu
export default withToolbarSwitcher()(observer(ToolbarMenu))
