import React, { useRef, useState } from 'react'
import './message-input.css'

function MessageInput({
    activeInput,
    placeholder = 'Type',
    addMessage,
}) {

    const [text, setText] = useState('')

    const textInput = useRef()

    const onMessageChange = (e) => {
        setText(e.target.value);
        setInputHeight();
    }
    
    const onInputKeyDown = (e) => {
        if (e.which === 13 && !e.shiftKey) {
            e.preventDefault();
            checkAndSendMessage();
        }
    }

    const checkAndSendMessage = () => {

        if(text.trim().length > 0){
            addNewMessage(text);
            
            textInput.current.focus();
        }
    }

    const addNewMessage = (text) =>{
        const message = {
            body:text,
            isUserMsg: true
        }
        addMessage(message);
        setText('')
    }

    const setInputHeight = () => {
        textInput.current.style.height = '';
        textInput.current.style.height = `${textInput.current.scrollHeight}px`;  
    }

    const inputClassName = `message-input ${!activeInput ? 'disabled-input':''}`

    return (
        <div className={inputClassName}>
            {/* {showBackButton === true?
                <div className="return-back-container">
                    <a href="#" onClick={(e) => this.onBackButtonClick(e)} disabled={!isActiveBackButton}>Back to previous question</a>
                </div>
                :""
            } */}

            <textarea
                id="chat-input"
                type="text"
                placeholder={placeholder}
                autoComplete='off'
                disabled={!activeInput} 
                value={text}
                autoFocus={false}
                onKeyDown={onInputKeyDown}
                onChange={onMessageChange}
                ref={textInput}>
            </textarea>

            <button className="send-btn std-button" onClick={checkAndSendMessage} disabled={!activeInput}>Send</button>
        </div>
    )
}

export default MessageInput
