import React from 'react'
import PRCFooter from '../../prc-footer';
import { ACTIVATION_STAGE_INFO } from './../../../../utils/activation-stages';

function ActivationInfo({
    activationStage,
    setActivationStage,
    setActivationType,
    setReferrerActivationStage
}) {

    const onFindProfileClick = (stage, type) => {
        setActivationType(type);
        setReferrerActivationStage(activationStage);
        setActivationStage(stage);
     }

    return (
        <div className={`activation-stage csm-stage activation-info ${activationStage === ACTIVATION_STAGE_INFO ? 'active-stage' : 'inactive-stage'}`}>
            <div className='stage-body body-with-prc-footer'>
                <div className='activatio-stage-info activation-benefits-title border-btm font-bold'>
                    Complete your profile for personalized content and the most efficient interactions with Emergent and Emergent sites.
                </div>
                <div className='profile-options'>
                    <div className='profile-option'>
                        <div className='option-desc'>If you've already created an Emergent Profile, we can reactivate it and get you started quickly.</div>
                        <span className='option-link std-link font-bold' onClick={() => onFindProfileClick('search', 'existing')}>Find my profile.</span>
                    </div>
                    <div className='profile-option'>
                        <div className='option-desc'>If you need to create a profile, it will only take a moment.</div>
                        <span className='option-link std-link font-bold' onClick={() => onFindProfileClick('search', 'new')}>I need a new profile.</span>
                    </div>
                </div>
                <PRCFooter absolute={true}/>   
            </div>       
        </div>
    )
}

export default ActivationInfo
