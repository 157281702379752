import React, {useContext} from 'react';
import { ACTIVATION_STAGE_ACTIVATION_SUCCESS } from '../../../../utils/activation-stages';
import './activation-success.css';
import * as config from '../../../../config';
import { AppContext } from '../../../../context';
import PrcFooter from '../../prc-footer';

function ActivationSuccess({
    activationStage,
    activationType,
    setActivationStage,
    onMenuButtonClick
}) {
    const{customerStore} = useContext(AppContext);
        
    const congratulationTitle = activationType === 'existing' 
                            ? `Congratulations ${customerStore.customerData.first_name} ${customerStore.customerData.last_name}, your Emergent Profile has been reactivated.`
                            : `Congratulations ${customerStore.customerData.first_name} ${customerStore.customerData.last_name}, your Emergent Profile has been activated.`;


    return (
        <div className={`activation-stage csm-stage activation-success ${activationStage === ACTIVATION_STAGE_ACTIVATION_SUCCESS ? 'active-stage' : 'inactive-stage'}`}>
            <div className='stage-body body-with-prc-footer'>
                <div className='congratulation-title font-bold'>{congratulationTitle}</div>            
                <img className="congratulation-sent-img" src={`${config.IMG_HOSTING}/images/sent-icon.svg`} alt="sent"></img>
                <div className='congratulation-description'>
                    A confirmation has been sent to the email address on file. 
                </div>
                <div className='buttons'>
                    <a href={window.ebsiChatConfig.resourceCenterLink} target='_blank' className='std-button'>Go to Resource Center</a>
                    <button className='std-button std-gray' onClick={() => onMenuButtonClick(null)}>Main menu</button>
                </div>
                <PrcFooter absolute={true}/>
            </div>
        </div>
    )
}

export default ActivationSuccess
