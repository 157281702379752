import React, {useContext} from 'react'
import { useFormik } from 'formik';
import './activate-profile-form.css';
import { activationValidator } from '../../../../utils/validators';
import { InputContainer } from '../claim-profile-form/claim-profile-form';
import { AUTH_MAIN_DOMAIN, SPECIALITIES } from '../../../../config';
import Select from 'react-select';
import {withNotify, withSpinner} from './../../../hoc-helpers';
import { AppContext } from '../../../../context';
import NumberFormat from 'react-number-format';

function ActivateProfileForm({
    customer,
    setActivationStage,
    onActivationSuccess,
    setLoading,
    showNotify,
    activationType,
    activationStage,
    setReferrerActivationStage,
    referrerActivationStage
}) {

    const {customerCenterService} = useContext(AppContext)

    const defaultSpeciality = SPECIALITIES.find(sp => sp.value === customer.speciality) || null;
    
    const formik = useFormik({
        initialValues: {
            id: customer.id,
            npi: customer.npi,
            email: customer.email,
            first_name: customer.first_name,
            last_name: customer.last_name,
            zip: customer.zip,
            speciality: customer.speciality,
            phone: customer.phone,
            opt_to_18: false,
            opt_to_email: true,
            opt_to_sms: true,
            agree_terms: false,
        },
        validate:(values) => activationValidator(values),
        onSubmit: async (values, { setSubmitting }) => {
            console.log(values);    
            
            try{
                setLoading(true);

                let response;
                if(values.id){
                    response = await customerCenterService.claimProfile(values);
                }
                else {
                    response = await customerCenterService.createProfile(values);
                }

                setLoading(false);

                if(response.success){

                    const newWin = window.open(`${AUTH_MAIN_DOMAIN}/Home/Authorize?userId=${response.customer.userId}&classOfTrade=${response.customer.class_of_trade}`, '_blank');

                    setTimeout(()=>{
                        if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
                            showNotify(false, "Sorry, you must enable pop ups in your browser to complete this process.");
                        }
                        else{
                            onActivationSuccess(response.customer);
                        }
                    })  
                }
                else{
                    showNotify(false, response.message)
                }
            }
            catch(err){
                setLoading(false);
                showNotify(false, "Sorry, there are some troubles while activation. Please, try again later.");
                console.log(err);
            }
        },
    });

    const onSpecialityChange = (option) => {
        console.log('new spec', option)
        formik.setFieldValue('speciality', option.value)
    }

    const onCancelForm = () => {
        let currentActivationStage = activationStage;
        
        setActivationStage(referrerActivationStage);        
        setReferrerActivationStage(currentActivationStage);
     }

    return (
        <div className='activate-profile-form'>
            <form onSubmit={formik.handleSubmit}>
                {/* <ProfileDetailsItem title="Phone Number" value={<NumberFormat value={customer.phone} displayType={'text'}  format="+# (###) ###-####"/>}/> */}
                {/* <ProfileDetailsItem title="NPI Number" value={customer.npi}/> */}
                <div className='name-fields' style={{display: 'flex'}}>
                    {/* <ProfileDetailsItem title="First Name" value={customer.first_name}/>
                    <ProfileDetailsItem title="Last Name" value={customer.last_name}/> */}
                    <InputContainer field='first_name' formik={formik} title="First Name"/>
                    <InputContainer field='last_name' formik={formik} title="Last Name"/>
                </div>
                {/* <ProfileDetailsItem title="Email Address" value={customer.email}/> */}
                <InputContainer field='email' formik={formik} title="Email Address"/>

                <div className='separator border-btm' style={{marginTop: '20px', marginBottom: '20px'}}></div>

                <div className='active-inputs'>
                    <InputContainer field='zip' formik={formik} title="ZIP Code"/>
                    <InputContainer field='npi' formik={formik} title="NPI Number (Optional)" validate={false}/>
                    <InputContainer field='speciality' formik={formik} title="Speciality">
                        <Select
                            name="speciality"
                            options={SPECIALITIES}
                            menuPlacement='top'
                            defaultValue={defaultSpeciality}
                            onChange={onSpecialityChange}
                            onBlur={formik.handleBlur}
                            className="widget-form-select"
                            classNamePrefix="widget-form-select"
                            placeholder=""
                        />
                        <span className="widget-form-error-text">{formik.errors.speciality && formik.touched.speciality && formik.errors.speciality}</span>                                     
                    </InputContainer>
                    <InputContainer field='opt_to_email' formik={formik} validate={false}>
                        <label className='widget-form-checkbox-block'>
                            <input                               
                                type="checkbox"                                                                
                                name="opt_to_email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.opt_to_email}
                            >
                            </input>
                            <span className='widget-form-checkbox-styled'></span>                          
                            <span className='widget-form-checkbox-title'>Yes, I would like to receive email communications</span>
                        </label>
                        <span className="widget-form-error-text">{formik.errors.opt_to_email && formik.touched.opt_to_email && formik.errors.opt_to_email}</span>
                    </InputContainer>
                    <InputContainer field='opt_to_sms' formik={formik} validate={false}>
                        <label className='widget-form-checkbox-block'>
                            <input                               
                                type="checkbox"                                                                
                                name="opt_to_sms"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.opt_to_sms}
                            >
                            </input>
                            <span className='widget-form-checkbox-styled'></span>                          
                            <span className='widget-form-checkbox-title'>Yes, I would like to receive SMS (text) communications</span>
                        </label>
                        <span className="widget-form-error-text">{formik.errors.opt_to_sms && formik.touched.opt_to_sms && formik.errors.opt_to_sms}</span>
                    </InputContainer>

                    {formik.values.opt_to_sms && <InputContainer  field='phone' formik={formik} title="">
                        <span style={{marginBottom: '3px', fontSize:'12px'}}>If yes, please provide your 10-digit mobile phone number</span>
                        <div className="widget-form-input-wrp">
                            
                            <NumberFormat
                                name="phone"
                                mask="_"
                                className="widget-form-input" 
                                value={formik.values['phone']} 
                                displayType={'input'}  
                                format="+# (###) ###-####"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />                               
                        </div> 
                        <span className="widget-form-error-text">{formik.errors['phone'] && formik.touched['phone'] && formik.errors['phone']}</span> 
                    </InputContainer>}

                    <InputContainer field='opt_to_18' formik={formik}>
                        <label className='widget-form-checkbox-block'>
                            <input                               
                                type="checkbox"                                                                
                                name="opt_to_18"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.opt_to_18}
                            >
                            </input>
                            <span className='widget-form-checkbox-styled'></span>                          
                            <span className='widget-form-checkbox-title'>Yes, I am over the age of 18 and a US citizen</span>
                        </label>
                        <span className="widget-form-error-text">{formik.errors.opt_to_18 && formik.touched.opt_to_18 && formik.errors.opt_to_18}</span>
                    </InputContainer>

                    <div className='separator border-btm' style={{marginTop: '20px', marginBottom: '20px'}}></div>

                    <InputContainer field='agree_terms' formik={formik}>
                        <label className='widget-form-checkbox-block'>
                            <input                               
                                type="checkbox"                                                                
                                name="agree_terms"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.agree_terms}           
                            >
                            </input>
                            <span className='widget-form-checkbox-styled'></span>                          
                            <span className='widget-form-checkbox-title'>I agree to the <a href={window.ebsiChatConfig.termsAndConditionsLink} target="_blank" rel="noreferrer" className='std-link' >Terms of Use</a>.</span>
                        </label>
                        <span className="widget-form-error-text">{formik.errors.agree_terms && formik.touched.agree_terms && formik.errors.agree_terms}</span>
                    </InputContainer>
                </div>
                <div className="toolbar-form-group form-buttons">
                    <button className="std-button std-gray cancel-btn" onClick={onCancelForm} type="submit">
                        Cancel
                    </button>
                    <button className="std-button submit-btn" type="submit">
                        Continue
                    </button>
                </div>
            </form>
        </div>
    )
}

export default withNotify()(withSpinner('100px', '100px')(ActivateProfileForm));
