import React, {useState} from 'react'
import './activation-search.css'
import CustomerSearchForm from '../../forms/customers-search-form';
import { ACTIVATION_STAGE_SEARCH } from '../../../../utils/activation-stages';
import PrcFooter from '../../prc-footer';

function ActivationSearch({
    activationStage,
    onCustomerSearchSuccess,
    onNpiRegistryClick,
    activationType,
    onCreateNewProfileClick,
    onCreateProfileManually
}) {

    const [currentFormType, setCurrentFormType] = useState('name')

    const onFormTypeChange = (event) => {
        setCurrentFormType(event.target.value)
    }

    const stageTitle = activationType === 'existing' ? 'Find your profile' : 'Create New Profile';

    return (
        <div className={`activation-stage csm-stage activation-search ${activationStage === ACTIVATION_STAGE_SEARCH ? 'active-stage' : 'inactive-stage'}`}>
            <div className='stage-title'>{stageTitle}</div>
            <div className='stage-body body-with-title body-with-prc-footer'>
                <div className='activatio-stage-info search-title font-bold'>To help create your profile, please enter the following information:</div>
                <div className='form-type-options border-btm'>
                    <div className='widget-form-group'>
                        <label className='widget-form-radio-block'>
                            <input
                                type="radio"
                                id="name-form-type"
                                name="search-form-type"
                                value="name"
                                checked={currentFormType === "name"}
                                onChange={onFormTypeChange}
                            />                            
                            <span className='widget-form-radio-styled'></span>                          
                            <span className='widget-form-radio-title font-bold'>Email address + name</span>
                        </label>
                    </div>
                    <div className='widget-form-group'>
                        <label className='widget-form-radio-block'>
                            <input
                                type="radio"
                                id="npi-form-type"
                                name="search-form-type"
                                value="npi" 
                                checked={currentFormType === "npi"}
                                onChange={onFormTypeChange}
                            />                     
                            <span className='widget-form-radio-styled'></span>                          
                            <span className='widget-form-radio-title font-bold'>Email address + NPI Number</span>
                        </label>                        
                    </div>
                </div>
                <div className='search-from'>
                    <CustomerSearchForm
                        formType={currentFormType}
                        onCustomerSearchSuccess={onCustomerSearchSuccess}
                        onNpiRegistryClick={onNpiRegistryClick}
                        activationType={activationType}/>
                </div>
                <div>
                    {activationType === 'new' && currentFormType === 'npi'
                        && <div style={{marginTop: '15px'}}>
                            Don't want to search by NPI? <span className='std-link' onClick={onCreateProfileManually}>Create your profile</span> manually.
                        </div>
                    }
                </div>
                <PrcFooter absolute={true}/> 
            </div>
            
        </div>
    )
}

export default ActivationSearch
