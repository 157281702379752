 export const processMessage = (sid, author, body, messageDate, channelId) => {
    let activeInput = false;
    let isActiveBackButton = false;
    console.log('processMessage', body)
    try{
        let twilioMsg = JSON.parse(`${body}`);
        
        if(typeof(twilioMsg) !== 'object'){
            throw new Error('Cannot parse body from Twilio msg');
        }
        activeInput =  (typeof(twilioMsg.buttons) !== 'undefined' && twilioMsg.buttons.length > 0)
                         ||(typeof(twilioMsg.options) !== 'undefined' && twilioMsg.options.length > 0)
                         ||(typeof(twilioMsg.endOfFlow) !== 'undefined' && twilioMsg.endOfFlow === true) ? false : true;
        isActiveBackButton = (twilioMsg.isActiveBackButton === true);
        return { ...twilioMsg, sid, messageDate, isUserMsg: author ===  channelId}
    }
    catch(ex){
        return { body, sid, messageDate, isUserMsg: author ===  channelId}
    }
}