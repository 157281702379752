import React, { useContext } from 'react';
import * as config from '../../../config';
import { AppContext } from '../../../context';
import { CollapseToolbarIcon, ExpandToolbarIcon } from '../toolbar-icons';

import './toolbar-item-header.css';

function ToolbarItemHeader({
    children,
    image,
    onBackButtonClick,
}) {

    const {uiStore} =  useContext(AppContext);

    const onCloseClick = () => {
        uiStore.setToolbarOpened(false);
    }

    const showCollapseIcon = uiStore.activeToolbarMenuItem === null
    const collapseIcon = !uiStore.toolbarExpanded
    ? <ExpandToolbarIcon onIconClick={(expanded) => uiStore.switchToolbar(expanded)}/> 
    : <CollapseToolbarIcon onIconClick={(expanded) => uiStore.switchToolbar(expanded)}/>

    return (
        <div className="toolbar-item-header">
            <div className='header-left-container'>
                {uiStore._activeToolbarMenuItem !==null && <button className="back-to-menu" onClick={() => onBackButtonClick(null)}><img  src={`${config.IMG_HOSTING}/images/back-icon.svg`} alt="back"></img></button>}
                {/* <img className="header-img" src={`${config.IMG_HOSTING}/${image}`} alt={children}/> */}
                <span className="header-title">{children}</span>
            </div>
            <div className='header-right-container'>
                {showCollapseIcon && collapseIcon}
                <span className='close-widget csm-header-icon' onClick={onCloseClick}><img  src={`${config.IMG_HOSTING}/images/close-icon.svg`} alt="close"></img></span>
            </div>
            
        </div>
    )
}

export default ToolbarItemHeader
