import React, {useContext, useEffect} from 'react';
import { useFormik } from 'formik';
import { withSpinner, withNotify } from '../../../hoc-helpers';
import { npiSearchValidator, nameSearchValidator } from './../../../../utils/validators';
import { InputContainer } from '../claim-profile-form/claim-profile-form';
import SearchFormFooter from '../../../cc-components/search-form-footer';
import { AppContext } from '../../../../context';

function CustomerSearchForm({
    formType,
    setLoading,
    showNotify,
    onCustomerSearchSuccess,
    onNpiRegistryClick,
    activationType,
}) {

    const {customerCenterService} = useContext(AppContext)

    const formValidator = formType==='npi' ? npiSearchValidator : nameSearchValidator;

    const formik = useFormik({
        initialValues: {
            npi: '',
            email: '',
            first_name: '',
            last_name: '',
        },
        validate:(values) => formValidator(activationType, values),
        onSubmit: async (values, { setSubmitting }) => {
            console.log(values);    
            
            try{
                setLoading(true);

                let response;
                if(formType==='npi'){

                    response = 
                        activationType === 'existing' 
                        ? await customerCenterService.getSearchResultsByNpi(values.npi, values.email)
                        : await customerCenterService.getSearchResultsByNpiForCreating(values.npi, values.email) ;
                }
                else{
                    
                    response =
                    activationType === 'existing' 
                    ? await customerCenterService.getSearchResultsByName(values.email, values.first_name, values.last_name) 
                    : await customerCenterService.getSearchResultsByNameForCreating(values.email, values.first_name, values.last_name);
                }
                
                console.log('customers search',response)

                if(response.success){
                    onCustomerSearchSuccess(response.responseObject)
                }
                else{
                    showNotify(false, response.message)
                }
            }
            catch(err){
                showNotify(false, "Sorry, there are some troubles while search. Please, try again later.");
                console.log(err);
            }
            finally{
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        formik.setTouched({...formik.touched, npi: false, email: false,first_name: false, last_name: false}, false)
    }, [formType, activationType])

    const {errors, touched, values, handleChange, handleBlur} = formik;

    const onNPIRegistryCLick = (e) => {
        e.preventDefault();
        onNpiRegistryClick();
    }

    const npiLookupLink = <a href='https://npiregistry.cms.hhs.gov/' target='_blank' rel="noreferrer" className='npi-lookup-link std-link' onClick={onNPIRegistryCLick}><b>+ NPI Lookup Tool</b></a>



    return (
        <div>
            {/* <div className='form-header-tools'>
                <span className='required-notify'>All fields are reuqired</span>
                {formType==='npi' &&<a href='https://npiregistry.cms.hhs.gov/' target='_blank' rel="noreferrer" className='npi-lookup-link'>NPI Lookup Tool</a>}
            </div> */}
            <form id='npi-search-from' onSubmit={formik.handleSubmit}>
                <InputContainer field='email' formik={formik} title="Email Address">
                    <div className='widget-form-input-wrp'>
                        <input
                            className="widget-form-input"
                            type="text"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            maxLength={40}
                        />     
                    </div>   
                    <span className="widget-form-error-text text-pl">{errors.email && touched.email && errors.email}</span>
                    <div className='text-pl' style={{fontSize:'12px'}}>Please enter the email address you most likely used to create your profile.</div>
                </InputContainer>

                <InputContainer 
                    field='npi'
                    formik={formik}
                    title={`NPI Number ${activationType === 'existing' ? '(Optional)' : ''}`}
                    show={formType==='npi'}
                    validate={activationType !== 'existing'}
                    inputAdditional={npiLookupLink}/>

                <InputContainer
                    field='first_name'
                    formik={formik}
                    title={`First Name ${activationType === 'existing' ? '(Optional)' : ''}`}
                    show={formType!=='npi'}
                    validate={activationType !== 'existing'}/>

                <InputContainer
                    field='last_name'
                    formik={formik}
                    title={`Last Name ${activationType === 'existing' ? '(Optional)' : ''}`}
                    show={formType!=='npi'}
                    validate={activationType !== 'existing'}/>

                <SearchFormFooter/>
            </form>
        </div>
    )
}

export default withNotify()(withSpinner('100px', '100px')(CustomerSearchForm));
