import React from 'react'
import { ACTIVATION_STAGE_ACTIVATION } from '../../../../utils/activation-stages'
import ActivateProfileForm from '../../forms/activate-profile-form'
import PrcFooter from '../../prc-footer';

const defaultCustomer = {
    id: '',
    npi: '',
    email: '',
    first_name: '',
    last_name: '',
    zip: '',
    phone: '',
    speciality: '',
    opt_to_email: true,
    opt_to_sms: true,
    agree_terms: false,
}

function CustomerActivation({
    activationStage,
    customer,
    setActivationStage,
    onActivationSuccess,
    activationType,
    setReferrerActivationStage,
    referrerActivationStage
}) {
    let stageTitle, greetings;
    if(activationType === 'existing'){
        stageTitle = 'Update Information';
        greetings = <>
            <span className="font-bold">It’s good to see you again {customer.first_name} {customer.last_name}!</span> <br/>
                    Please provide a few more details to complete your reactivation.
        </>
    }
    else{
        if(customer){
            stageTitle = 'Complete Your Profile';
            greetings = <>
                <span className="font-bold">It’s good to see you {customer.first_name} {customer.last_name}!</span> <br/>
                Please provide a few more details to complete your activation.
            </>
        }
        else{
            stageTitle = 'Enter Your Details';
            greetings = <>
                <span className="font-bold">Please enter the following information to complete your profile: </span>
            </>
        }
    }
    return (
        <div className={`activation-stage csm-stage customer-activation ${activationStage === ACTIVATION_STAGE_ACTIVATION ? 'active-stage' : 'inactive-stage'}`}>
            <div className='stage-title'>{stageTitle}</div>
            <div className='stage-body body-with-title body-with-prc-footer'>
                <div style={{paddingBottom: '15px'}} className='activatio-stage-info user-greetings border-btm'>
                   {greetings}
                </div>
                <ActivateProfileForm customer={customer || defaultCustomer} setActivationStage={setActivationStage} onActivationSuccess={onActivationSuccess} activationType={activationType} activationStage={activationStage} setReferrerActivationStage={setReferrerActivationStage} referrerActivationStage={referrerActivationStage}/>
                <PrcFooter absolute={true}/>
            </div> 
        </div>
    )
}

export default CustomerActivation
