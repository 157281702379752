const PHONE_REQUIRED_DEGITS_COUNT = 11;

export const signUpValidator = (values) => {
    const errors = {};
        if (!values.first_name) {
            errors.first_name = 'Required';
        }
        if (!values.last_name) {
            errors.last_name = 'Required';
        }
        if (!values.email) {
            errors.email = 'Required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }

        if (!values.speciality) {
            errors.speciality = 'Required';
        }

        if (!values.accept) {
            errors.accept = 'Required';
        }

        return errors;
}

export const signInValidator = (values, signInStatus) => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Required';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address';
    }
    if(!values.securityCode && signInStatus === 'waitingForCode'){
        errors.securityCode = 'Required';
    }

    return errors;
}

export const editCustomerDataValidator = (values) => {
    const errors = {};
        if (!values.first_name) {
            errors.first_name = 'Required';
        }
        if (!values.last_name) {
            errors.last_name = 'Required';
        }
        if (!values.email) {
            errors.email = 'Required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }

        // if (!values.speciality) {
        //     errors.speciality = 'Required';
        // }
        
        const phoneDigits = values.phone.replace(/[^0-9]/g,'');       
        if(phoneDigits.length < PHONE_REQUIRED_DEGITS_COUNT){
            errors.phone = 'Required';
        }

        return errors;
}

export const npiValidator = (values) => {
    const errors ={}
    if(!values.npi){
        errors.npi = 'Required';
    }
    return errors;
}

export const claimValidator = (values) => {
    const errors = {};

    if (!values.npi) {
        errors.npi = 'Required';
    }

    if (!values.first_name) {
        errors.first_name = 'Required';
    }

    if (!values.last_name) {
        errors.last_name = 'Required';
    }

    if (!values.email) {
        errors.email = 'Required';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address';
    }

    if (!values.zip) {
        errors.zip = 'Required';
    }

    if (!values.speciality) {
        errors.speciality = 'Required';
    }

    if(!values.agree_terms){
        errors.agree_terms = 'Required';
    }

    return errors;
}

export const setFieldsErrors = (errors, setFieldError) => {
    for (const field in errors) {
        if (Object.hasOwnProperty.call(errors, field)) {
            setFieldError(field, errors[field]);
            
        }
    }
}

export const validateNpiSearch = (formik) => {
    console.log(formik.touched)
    // formik.setTouched({...formik.touched.map(field => {
    //     return {
    //         `${field.key}`: false
    //     }
    // })}, false);
    let valid = true;
    if(!formik.values.first_name){
        valid = false;
        formik.setFieldError('first_name', 'Required');
        // formik.setTouched({...formik.touched, first_name: true}, false)
    }
    if(!formik.values.last_name){
        valid = false;
        formik.setFieldError('last_name', 'Required')
        // formik.setTouched({...formik.touched, last_name: true}, false)
    }
    formik.setTouched({...formik.touched, first_name: true, last_name: true}, false)
    return valid;
}

export const npiSearchValidator = (activationType, values) => {
    const errors = {};

    if (activationType !== 'existing' && (!values.npi || values.npi.trim().length === 0)) {
        errors.npi = 'Required';
    }
    // else if(!/^\d{10}$/i.test(values.npi)){
    //     errors.npi = 'Invalid NPI number';
    // }

    if (!values.email) {
        errors.email = 'Required';
    }
    else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address';
    }

    return errors;
}

export const nameSearchValidator = (activationType, values) => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Required';
    }
    else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address';
    }

    if (activationType !== 'existing' && (!values.first_name || values.first_name.trim().length === 0)) {
        errors.first_name = 'Required';
    }

    if (activationType !== 'existing' &&(!values.last_name || values.last_name.trim().length === 0)) {
        errors.last_name = 'Required';
    }

    return errors;
}

export const activationValidator = (values) => {
    console.log('validation',values);
    const errors = {};
    if (!values.email) {
        errors.email = 'Required';
    }
    else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address';
    }

    if (!values.first_name || values.first_name.trim().length === 0) {
        errors.first_name = 'Required';
    }

    if (!values.last_name || values.last_name.trim().length === 0) {
        errors.last_name = 'Required';
    }

    if (!values.zip) {
        errors.zip = 'Required';
    }

    // const phoneDigits = values.phone ? values.phone.replace(/[^0-9]/g,'') : '';       
    
    // if(phoneDigits.length < PHONE_REQUIRED_DEGITS_COUNT && values.opt_to_sms){
    //     errors.phone = 'Required';
    // }

    if (!values.speciality) {
        errors.speciality = 'Required';
    }

    if(!values.agree_terms){
        errors.agree_terms = 'Required';
    }

    if(!values.opt_to_18){
        errors.opt_to_18 = 'Required';
    }

    return errors;
}