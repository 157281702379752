import React from 'react';

function CookiesDefault(props) {
    return (
        <div className="cookies-default">
            <div className='cookies-meta'>            
                <div className="cookies-content-block cookies-title font-bold">
                    We use cookies on this site to enhance your user experience.
                </div>
                <div className="cookies-content-block cookies-text">
                    By clicking ‘Accept,’ you agree to our use of cookies on this site.
                </div>
                <div className="cookies-content-block cookies-text">
                    Visit our <a href={window.ebsiChatConfig.privacyPolicyLink} className='std-link' target='_blank' rel="noreferrer">Privacy Policy</a> for more information about our data collection practices.
                </div>
            </div>
            <div className="cookies-content-block cookies-buttons">
                <button className="std-button std-gray" onClick={props.onDeclineUserPrivacyPolicy}>Decline</button>
                <button className="std-button" onClick={props.onAgreePrivacyPolicy}>Accept</button>
            </div>
        </div>
    )
}

function CookiesAgreed(props) {
    return (
        <div className="cookies-agreed">
            <div className='cookies-meta'> 
                <div className="cookies-content-block cookies-title font-bold">
                    Thank you.
                </div>
                <div className="cookies-content-block cookies-text">
                    You can now hide this message or review our <a href={window.ebsiChatConfig.privacyPolicyLink} className='std-link' target='_blank' rel="noreferrer">Privacy Policy</a> for more information about our data collection practices.
                </div>
            </div>
            <div className="cookies-content-block cookies-buttons">
                <button className="std-button std-gray" onClick={() => props.onHideCookiesPolicy(false)}>Hide</button>
                <button className="std-button" onClick={() => props.onHideCookiesPolicy(true)}>More Info</button>
            </div>
        </div>
    )
}

export { 
    CookiesDefault,
    CookiesAgreed,    
}