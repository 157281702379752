import React, {useContext} from 'react'
import { AppContext } from '../../context';
import { observer } from 'mobx-react-lite';

function AuthChecker({
    switchActiveToolbarItem,
    children
}) {

    const{customerStore} = useContext(AppContext);

    if(customerStore.customerData.userId != null){
        return children;
    }
    else{
        return (
            <div className="auth-checker">
                Please, <span className="toolbar-link" style={{fontSize:'16px'}} onClick={() => switchActiveToolbarItem('claim-profile')}>claim your profile</span> to have access to this functionality
            </div>
        )
    }

}

export default observer(AuthChecker);