import React, {useState} from 'react'
import './search-results.css';
import * as config from '../../../../config';
import { ACTIVATION_STAGE_SEARCH_RESULTS } from './../../../../utils/activation-stages';
import PrcFooter from '../../prc-footer';

function SearchResults({
    activationStage,
    customers,
    setActivationStage,
    onCustomerClick,
    activationType,
    onCreateNewProfileClick,
    onCreateProfileManually
}) {
    // const fieldsToShow = [{title:'Email', value: 'email', alternativeTitle: 'NPI', alternativeValue: 'npi'}]
    const fieldsToShow = [
        {title:'Email', value: 'email'},
        {title:'NPI', value: 'npi'},
        {title:'Phone', value: 'phone'},
    ]

    const createNewProfileBlock = <span style={{marginBottom: '10px'}}>
        Can’t find your information? No problem,
        you can still <span className='std-link' onClick={onCreateProfileManually}>create your profile</span> by
        entering your details manually.
    </span>

    let searchContent;

    if(customers.length <= 0)
    {
        const noResultText = activationType === 'existing' 
                            ? 'We were unable to find a match for the information you entered. Please try your search again or create a new profile.' 
                            : 'We were unable to find a match for the information you entered. Please double check your search terms.';
        searchContent = <>
            <div className='activatio-stage-info font-bold'>We’re sorry.</div>
            
            <div style={{marginBottom: '10px', fontSize:'14px'}} className='activation-benefits-title'>
                {noResultText}
            </div>
            {activationType === 'existing' 
                ? <span style={{marginBottom: '10px'}} className='std-link claim-btn font-bold' onClick={onCreateNewProfileClick}>Create a new profile</span>
                : createNewProfileBlock
            }
        </>
    }
    else{
        searchContent = <>
            <div className='activatio-stage-info font-bold'>We found the following profile(s).<br/> Please select yours below:</div>
                <SearchResultsList customers={customers} fields={fieldsToShow} onCustomerClick={onCustomerClick} />
                
                {activationType === 'existing' 
                    ? <div>
                        <div style={{marginBottom: '10px', fontSize:'14px'}} className='activation-benefits-title font-bold'>
                            Don’t see a match? Please create a new profile.
                        </div>
                        <span style={{marginBottom: '10px'}} className='std-link claim-btn font-bold' onClick={onCreateNewProfileClick}>Create a new profile</span>
                    </div>
                    : createNewProfileBlock
                }
        </>
    }

    return (
        <div className={`activation-stage csm-stage search-results ${activationStage === ACTIVATION_STAGE_SEARCH_RESULTS ? 'active-stage' : 'inactive-stage'}`}>
            <div className='stage-title'>Search Results</div>
            <div className='stage-body body-with-title body-with-prc-footer'>
               {searchContent}
               <PrcFooter absolute={true}/>
            </div>
            
        </div>
    )
}

export default SearchResults;

export const SearchResultsList = ({customers, fields, onCustomerClick}) => {
    return(
        <ul className='search-results-list'>
            {customers.map(customer => {
                return (
                    <SearchResultItem key={customer.id} customer={customer} onCustomerClick={onCustomerClick} fields={fields}/>
                )
            })}
        </ul>
    )
 }

const SearchResultItem = ({
    customer,
    onCustomerClick,
    fields,
}) => {
    const [expanded, setExpanded] = useState(false);

    return(
        <li className={`search-customer-item ${expanded ? 'expanded' : ''}`} key={customer.id}>
            <div className='search-customer-header'>
                <div className='search-customer-name' onClick={() => onCustomerClick(customer.id)}>{customer.first_name} {customer.last_name}</div>
                <div className='search-customer-expand-icon' onClick={() => setExpanded(!expanded)}>
                    <img  src={`${config.IMG_HOSTING}/images/back-icon.svg`} alt="back"></img>
                </div>
            </div>

            <div className='search-customer-info'>
                {fields.map(field => {
                    return <div key={field.value} className='search-customer-field'><b>{field.title}:</b> {customer[field.value]}</div>
                })}
                {/* <div className='search-customer-npi'><b>NPI Number:</b> {customer.npi}</div>
                <div className='search-customer-email'><b>Email:</b> {customer.email}</div> */}
            </div>
        </li>
    )
}
