import React from 'react';
import './spinner.css';
import * as config from '../../config';

export default function Spinner ({width="200px", height="200px", paddingTop="0px"}) {
    return (
        <div className="spinner">
            <div className="spinner-bg" style={{paddingTop: paddingTop}}>
                {/* <div className="loadingio-spinner-dual-ring-aiwvifvd0tb"><div className="ldio-91d8ciko2rs">
                    <div></div><div><div></div></div>
                    </div>
                </div> */}
                <img width={width} height={height} src={`${config.IMG_HOSTING}/images/spinner.svg`} alt="Loading..."/>
            </div>
        </div>
    )
}
