import React, { useContext } from 'react'
import './customer-toolbar-container.css';
import { observer } from 'mobx-react-lite';
import {AppContext} from './../../../context';
import ToolbarItems from './../toolbar-items';
import ToolbarMenu from './../toolbar-menu';
import { getMenuItems } from '../../../utils/functions';

function CustomerToolbarContainer() {
    
    const{privacyPolicyStore, uiStore, customerStore} = useContext(AppContext);

    const menuItems = getMenuItems({
        menuType: window.ebsiChatConfig.csmType,
        userId: customerStore.customerData.userId,
        userCommunicationPreferenceEmail: customerStore.userCommunicationPreferenceEmail
    })

    let containerClass = "customer-toolbar";
    if(privacyPolicyStore.privacyPolicyStatus === 'hidden' && uiStore.toolbarOpened){
        containerClass += ' toolbar-open'
    }
    if(uiStore.toolbarExpanded){
        containerClass += ' toolbar-expanded'
    }
    if(uiStore.calling){
        containerClass += ' active-call'
    }

    return (
        <div className={containerClass}>
            
            <ToolbarItems toolbarExpanded={uiStore.toolbarExpanded}/>

            <ToolbarMenu menuItems={menuItems}/>
        </div>
    )

}

export default observer(CustomerToolbarContainer)
