import React from 'react'
import './chat-button.css'

function ChatButton({
    messageSid,
    label,
    value,
    type,
    continueFlow,
    onButtonClick,
    onMessageLinkClick,
    target,
    download,
 }) {

    const onButtonClickEventHandler = (e, messageSid, label, value) => {
        e.preventDefault();
        onButtonClick(messageSid, label, value);
    }
    
    const additionalAttributes = {};
    if(target === '_blank'){
        additionalAttributes['rel'] = 'noopener noreferrer';
    }
    if(download){
        additionalAttributes['download'] = 'New document';
    }

    return (
        type === 'link' 
            ? <a className='std-button ebsi-chat-button' href={value} target={target} onClick={(e) => onMessageLinkClick(messageSid, label, value,continueFlow)} {...additionalAttributes}>{label}</a>
            : <a className='std-button ebsi-chat-button' href='/#' onClick={(e) => onButtonClickEventHandler(e, messageSid, label, value)}>{label}</a>
    )
}

export default ChatButton
