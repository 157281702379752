import React from 'react'
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';
import * as config from '../../../config';
import './voice-call.css';

function VoiceCall({
    onVoiceCallEnd,
}) {
    return (
        <div className="voice-call">                
            <div className="progress-call">
                <div className="call-indicator">
                    <div className="call-indicator-content">
                        <div className="call-indicator-inner white-green">
                            <img src={`${config.IMG_HOSTING}/images/phone-icon.svg`} alt="phone" />
                        </div>
                    </div>
                </div>
                <div className="call-timer"> 
                    <Timer active duration={null}>
                        <Timecode format="H:?mm:ss"/>
                    </Timer> 
                </div> 
            </div>
            <button className="button-hangup" 
                href="#" 
                onClick={ onVoiceCallEnd }>
                <span className="call-indicator-inner white-red">
                    <img src={`${config.IMG_HOSTING}/images/phone-icon.svg`} alt="phone" />
                </span>
                <span className="button-label">End Call</span>
            </button>
        </div>
    )
}

export default VoiceCall
