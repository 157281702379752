import React, { useState } from 'react'
import ToolbarNotify from '../toolbar-notify/toolbar-notify'

const withNotify = () => (View) => {
    
    return (props) =>{
       const [notifyIsDisplaying, setNotifyIsDisplaying] = useState(false);
       const [notifySuccess, setNotifySuccess] = useState(true);
       const [notifyMessage, setNotifyMessage] = useState('');
        
       const showNotify = (success, message, duration = 5000) => {
            setNotifyIsDisplaying(true);
            setNotifySuccess(success);
            setNotifyMessage(message);

            setTimeout(()=>{
                setNotifyIsDisplaying(false);
            }, duration);
        }

        // const closeNotify = (e) =>{
        //     e.preventDefault();
        // }

        return(
            <div className='with-notify'>
                {<ToolbarNotify success={notifySuccess} message={notifyMessage} displaying={notifyIsDisplaying}/>}
                <View {...props} showNotify={showNotify}/>
            </div>
        )
    }
}

export default withNotify;
