import React from 'react'
import { observer } from 'mobx-react-lite';
import PreferenceItem from './preference-item';

function CommunicationPreferencesList({   
    emailPreferences,
    onChangeEmailItemSubscribe,
    onChangeEmailListSubscribe
}){
    return (
        <div className="communications-list preference-list">  
            <div>
                <div className="preference-item">
                        <PreferenceItem 
                            emailPreferences={emailPreferences} 
                            onChangeEmailItemSubscribe={onChangeEmailItemSubscribe}
                            onChangeEmailListSubscribe={onChangeEmailListSubscribe}
                        />                                    
                </div>
            </div>
        </div>
    )
}

export default observer(CommunicationPreferencesList)


