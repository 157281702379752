import React from 'react'

const getPreferenceIconClass = (id) => {
    switch (id) {
        case 'email':
            return 'fa-envelope'
        case 'phone':
            return 'fa-volume-control-phone'
        case 'sms':
            return 'fa-weixin'
        case 'address':
            return 'fa-map-marker'
        default:
            return ''
    }
}

function PreferenceItem({emailPreferences, onChangeEmailItemSubscribe, onChangeEmailListSubscribe}) {    
        
    const setEmailPreferenceActive = (emailPreferenceActive) =>{
        onChangeEmailListSubscribe(emailPreferenceActive);           
    }

    const infoBlock = (index, subscribeLink, listTitle) => {        
        return(        
        <div className='info-block'>
            <p>You are not subscribed to {listTitle}.</p>
            <p>To subscribe to this topic, please visit <a href={subscribeLink} target="_blank" rel="noreferrer" className='std-link' >{subscribeLink}</a>.</p>            
        </div>
        )
      };    

    const emailPreferenceList = emailPreferences.map((emailPreference, index) => {        
        return(
            <div className='widget-form-group' key={index}>
                <label className={`widget-form-checkbox-block ${emailPreference.manualUnsubscribed? 'disabled':''}`}>
                    <input                               
                        type="checkbox"
                        name="topic1"
                        checked={emailPreference.subscribed} 
                        onChange={() => onChangeEmailItemSubscribe(emailPreference.audienceId, !emailPreference.subscribed, emailPreference.manualUnsubscribed)}
                    />
                    <span className='widget-form-checkbox-styled'></span>                          
                    <div className='widget-form-checkbox-title'>
                        {emailPreference.listTitle}
                        {emailPreference.manualUnsubscribed && infoBlock(index, emailPreference.subscribeLink, emailPreference.listTitle)}
                    </div>                                        
                </label>
            </div>           
        )
    }) 
    
    const emailPreferenceActive = emailPreferences.some(pref => pref.subscribed === true);

    return (
        <div className="preference-item-main" >            
            <div className="preference-header preference-check">
                <label className="switch"> 
                    <span className='preference-item-title-block'>                                   
                        <span className={`preference-item-title ${emailPreferenceActive ? 'preference-active': null}`}>Email</span>
                    </span>
                    <span className='check-block'>
                        <span className='checkbox-title'>{emailPreferenceActive ? 'Yes' : 'No'}</span>
                        <input type="checkbox" 
                            onChange={() => setEmailPreferenceActive(!emailPreferenceActive)} 
                            checked={emailPreferenceActive}/>
                        <span className={`slider round ${emailPreferenceActive ? null : 'inactive'}`}></span>                       
                    </span>
                </label>                
            </div>   
            <div className={`preference-description`}>
                {emailPreferenceList}                
            </div>
        </div>
    )
}

export default PreferenceItem
