import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { AppContext } from '../../../context';
import UserChat from '../../csm-chat/user-chat';
import PrcFooter from '../prc-footer';
import { CONTACT_STAGE_WEB_CHAT } from './../../../utils/contact-stages';

function ContactChat({
    chatType,
    onStartOverChat,
}) {

    const {chatStore} = useContext(AppContext)

    return (
        <div className={`contact-us-stage csm-stage contact-us-chat ${chatStore.contactStage === CONTACT_STAGE_WEB_CHAT ? 'active-stage' : 'inactive-stage'}`}>
            <div className='stage-title' style={{display:'flex', justifyContent:'space-between'}}>
                <span>{chatType === 'web' ? "Live Chat" : "Call Us"}</span>              
            </div>
            <div className='stage-body body-with-title'>
                <UserChat
                    chatType={chatType}
                    onStartOverChat={onStartOverChat}/>
                <PrcFooter absolute={true}/>
            </div>
        </div>
    )
}

export default observer(ContactChat)
