import React, {useContext} from 'react'
import { useFormik } from 'formik';
import {withSpinner} from '../../../hoc-helpers';
import { InputContainer } from '../claim-profile-form/claim-profile-form';
import { editCustomerDataValidator } from '../../../../utils/validators';
import withNotify from './../../../hoc-helpers/with-notify';
import { AppContext } from '../../../../context';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { SPECIALITIES } from '../../../../config';
import PrcFooter from '../../prc-footer';
import {   
    PROFILE_STAGE_DETAILS
} from '../../../../utils/profile-stages';

function EditProfileForm({
    customer,
    onCancelButtonClick,
    setLoading,
    showNotify,
    onSuccessEdit,}) {

    const {customerCenterService, customerStore} = useContext(AppContext)

    const defaultSpeciality = SPECIALITIES.find(sp => sp.value === customer.speciality) || null;

    const onSpecialityChange = (option) => {
        formik.setFieldValue('speciality', option.value)
    }   

    const formik = useFormik({
        initialValues: {
            id: customer.userId,
            npi: customer.npi,
            email: customer.email,
            // retype_email: '',
            first_name: customer.first_name,
            last_name: customer.last_name,
            phone: customer.phone,
            zip: customer.zip,
            speciality: customer.speciality            
        },
        validate:(values) => editCustomerDataValidator(values),
        onSubmit: (values, { setSubmitting }) => {
            console.log(values);    
            
            setLoading(true);
            
            const phoneDigits = values.phone.replace(/[^0-9]/g,'');

            customerCenterService
                .updateCustomerData(
                    values.id,
                    values.npi,
                    values.first_name,
                    values.last_name,
                    values.email,
                    phoneDigits,
                    values.zip,
                    values.speciality,

                )
                .then(response => {
                    if(response.success){
                        onSuccessEdit(response.user)
                    }   
                    else{
                        showNotify(false, response.message)
                    }
                })
                .catch(err => {
                    showNotify(false, 'Sorry, it seems there is a problem with update. Please, try again later.')
                })
                .finally(() => {
                    setLoading(false);
                })

            
        },
    });

    const form = () => {
        // const {errors, touched, values, handleChange, handleBlur} = formik;
        return(
            <div className='edit-profile-form'>
                <div className='stage-title'>Edit Your Profile</div>
                <div className='stage-body body-with-title body-with-prc-footer'>
                    <div className="profile-details-title font-bold border-btm">
                        Here is your current profile:                    
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        
                        <InputContainer field='first_name' formik={formik} title="First Name"/>
                        <InputContainer field='last_name' formik={formik} title="Last Name"/>
                        <InputContainer field='email' formik={formik} title="Email Address"/>
                        {/* <InputContainer field='retype_email' formik={formik} title="Re-enter Email address"/> */}
                        <InputContainer field='zip' formik={formik} title="ZIP Code"/>
                        <InputContainer field='npi' formik={formik} title="NPI Number (Optional)" validate={false}/>
                        <InputContainer field='speciality' formik={formik} title="Speciality">
                            <Select
                                name="speciality"
                                options={SPECIALITIES}
                                menuPlacement='top'
                                defaultValue={defaultSpeciality}
                                onChange={onSpecialityChange}
                                onBlur={formik.handleBlur}
                                className="widget-form-select"
                                classNamePrefix="widget-form-select"
                                placeholder=""
                            />
                        </InputContainer>
                        <InputContainer  field='phone' formik={formik} title="Phone Number">
                            <div className="widget-form-input-wrp">
                                <NumberFormat
                                    name="phone"
                                    mask="_"
                                    className="widget-form-input" 
                                    value={formik.values['phone']} 
                                    displayType={'input'}  
                                    format="+# (###) ###-####"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />                               
                            </div> 
                            <span className="widget-form-error-text">{formik.errors['phone'] && formik.touched['phone'] && formik.errors['phone']}</span> 
                        </InputContainer>

                        <div className="profile-buttons-group">
                            <button className="std-button std-gray" type="button" onClick={()=>onCancelButtonClick(PROFILE_STAGE_DETAILS)}>
                                Back
                            </button>
                            <button className="std-button" type="submit">
                                Save
                            </button>
                        </div>
                        
                    </form>
                    <PrcFooter absolute={true}/>
                </div>
            </div>
        )
    }

    return (
        <div className="claim-profile-form form-canvas active-form">
            {form()}
        </div>
    )
}

export default withNotify()(withSpinner('100px', '100px', '150px')(EditProfileForm));