import React from 'react';
import './csm-modal.css';

function CsmModal ({showModal, modalContent, setShowModal}) {

    return (
        <>
            <div className={`csm-modal-overlay ${showModal ? 'active' : ''}`} id="csm-modal-overlay" onClick={() => setShowModal(false)}></div>
            <div className={`csm-modal ${showModal ? 'active' : ''}`}>
                <div className='csm-modal-header'>
                    {/* <span className='close-widget'><i className="fa fa-times" aria-hidden="true"></i></span> */}
                </div>
                <div className="modal-guts">
                    {modalContent}
                </div>
            </div>
        </>
    )
}

export default CsmModal;
