const LOCAL_STORAGE_ITEM_ID = "TWILIO_WEBCHAT_WIDGET";

export function storeSessionData(data) {
    localStorage.setItem(LOCAL_STORAGE_ITEM_ID, JSON.stringify(data));
}

export function getStoredSessionData() {
    const item = localStorage.getItem(LOCAL_STORAGE_ITEM_ID);
    let storedData;

    if (!item) {
        return null;
    }

    try {
        storedData = JSON.parse(item);
    } catch (e) {
        console.log("Couldn't parse locally stored data");
        return null;
    }

    // storedData = {
    //     conversationSid: 'CH272bd6e43f5d4fcdaeba21a6cfe1a8bb',
    //     token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJpc3MiOiJTS2MwOGM5M2QwZWIzMjljNzBkMjk1YTZmYjRjMjU0YTk4IiwiZXhwIjoxNjU2NDAzNjkxLCJqdGkiOiJTS2MwOGM5M2QwZWIzMjljNzBkMjk1YTZmYjRjMjU0YTk4LTE2NTY0MDAwOTEiLCJzdWIiOiJBQzdjYTU0YTMzMDk0NDQwNmYwZDIwNjg4MDJmNThmMTE3IiwiZ3JhbnRzIjp7ImlkZW50aXR5IjoiZmJhYzBjMmYtNDYyZS00MzIwLWI1ODEtYjVlMDBkOGZiMmE2IiwidmlkZW8iOnt9LCJjaGF0Ijp7InNlcnZpY2Vfc2lkIjoiSVMyN2U5ZGUyNDJmNDQ0YjFmYTUyNWRjOTcyZDg3ZmI5YSJ9LCJkYXRhX3N5bmMiOnsic2VydmljZV9zaWQiOiJkZWZhdWx0In0sInZvaWNlIjp7ImluY29taW5nIjp7ImFsbG93Ijp0cnVlfSwib3V0Z29pbmciOnsiYXBwbGljYXRpb25fc2lkIjoiQVAwMWE3ZWQ2YTkyYTkwZmU5M2Y3NDJkMDhkNjYzYjU1ZCJ9fX19.mjcFbjADxDRDXEnQmPH1XgLDXJsJ38fY3zx9fSvRX48'
    // }

    return storedData;
}

export default class SessionDataHandler {

    _chatService = null;

    constructor(chatService){
        this._chatService = chatService;
    }

    tryResumeExistingSession() {
        console.log("sessionDataHandler: trying to refresh existing session");
        const storedTokenData = getStoredSessionData();

        if (!storedTokenData) {
            console.log("sessionDataHandler: no tokens stored, no session to refresh");
            return null;
        }

        // if (Date.now() >= new Date(storedTokenData.expiration).getTime()) {
        //     console.log("sessionDataHandler: token expired, ignoring existing sessions");
        //     return null;
        // }

        console.log("sessionDataHandler: existing token still valid, using existing session data");
        return storedTokenData;
    }

    async fetchAndStoreNewSession(chatUserFriendlyName){
        let conversationSid;
        let identity;
        let token;

        try {
            const newFlexConversation = await this._chatService.createConversationFlexChannel(chatUserFriendlyName);

            conversationSid = newFlexConversation.conversation_sid;
            identity = newFlexConversation.identity;
            

            const newTokenData= await this._chatService.getToken(identity, 'csm-chat');
            token = newTokenData.token;

            console.log('identity', identity);
            console.log('conversationSid', conversationSid);
            console.log('token', token);


            storeSessionData({conversationSid, token});
            
        } catch (e) {
            throw Error("No results from server");
        }

        return {
            conversationSid,
            identity,
            token
        }

    }

    async getUpdatedToken(identity) {
        console.log("sessionDataHandler: trying to get updated token from BE");
        const storedTokenData = getStoredSessionData();

        if (!storedTokenData) {
            throw Error("Can't update token: current token doesn't exist");
        }

        let newTokenData;

        try {
            const newTokenData= await this._chatService.getToken(identity, 'csm-chat');
            const token = newTokenData.token;

            console.log('token', token);


            // storeSessionData({conversationSid, token});
        } catch (e) {
            throw Error(`Something went wrong when trying to get an updated token: ${e}`);
        }

        // Server won't return a conversation SID, so we merge the existing data with the latest one
        const updatedSessionData = {
            ...storedTokenData,
            ...newTokenData
        };

        storeSessionData(updatedSessionData);

        return updatedSessionData;
    }

    // async getUpdatedToken() {
    //     console.log("sessionDataHandler: trying to get updated token from BE");
    //     const storedTokenData = getStoredSessionData();

    //     if (!storedTokenData) {
    //         throw Error("Can't update token: current token doesn't exist");
    //     }

    //     let newTokenData;

    //     try {
    //         newTokenData = await this._chatService.getToken(identity, 'csm-chat');
    //         // newTokenData = await this._chatService.refreshToken(identity, 'csm-chat');
    //         // newTokenData = await contactBackend<Token>("/refreshToken", {
    //         //     token: storedTokenData.token
    //         // });
    //     } catch (e) {
    //         throw Error(`Something went wrong when trying to get an updated token: ${e}`);
    //     }

    //     // Server won't return a conversation SID, so we merge the existing data with the latest one
    //     const updatedSessionData = {
    //         ...storedTokenData,
    //         ...newTokenData
    //     };

    //     storeSessionData(updatedSessionData);

    //     return updatedSessionData;
    // }
}