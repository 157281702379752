import { createGlobalStyle} from 'styled-components'

export const GlobalStyles = createGlobalStyle`
.customer-toolbar {
    background-color: ${({ theme }) => theme.body};
}
.std-button{
    border-color: ${({ theme }) => theme.mainColor};
    background-color: ${({ theme }) => theme.mainColor};
}
.std-button:hover{
    color: ${({ theme }) => theme.mainColor};
}
.std-button[disabled]:hover{
    background-color: ${({ theme }) => theme.mainColor};
}
.std-button.std-inverse{
    color: ${({ theme }) => theme.mainColor};
}
.std-button.std-inverse:hover{
    background-color: ${({ theme }) => theme.mainColor};
}

.std-link{
    color: ${({ theme }) => theme.mainColor};
}

.toolbar-item-header .back-to-menu{
    color: ${({ theme }) => theme.mainColor};
}
.customer-toolbar.toolbar-expanded .tb-menu-item .tb-item-text{
    color: ${({ theme }) => theme.mainColor};
}
.toolbar-item-header .header-title{
    color: ${({ theme }) => theme.itemHeaderColor};
}
.toolbar-items .toolbar-item{
    border-color: ${({ theme }) => theme.mainColor};
}
.toolbar-items .toolbar-item .item-main .stage-title{
    color: ${({ theme }) => theme.mainColor};
    border-bottom: 2px solid ${({ theme }) => theme.mainColor};
}
.toolbar-items .toolbar-item::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.mainColor};
}
.toolbar-items .toolbar-item .item-main::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.mainColor};
}
.toolbar-items .toolbar-item .stage-body::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.mainColor};
}
.toolbar-items .toolbar-item #messages::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.mainColor};
}
.toolbar-items .toolbar-item .item-main .preference-list .preference-item .switch .slider{
    border-color:  ${({ theme }) => theme.checkboxColor};
    background-color: ${({ theme }) => theme.checkboxColor};
}
//.widget-button .menu-control-btn:hover{
//    background-color: ${({ theme }) => theme.widgetButtonHover};
//}
// .widget-form-group .widget-form-select .widget-form-select__control{
//     border-color: ${({ theme }) => theme.mainColor};
// }
.widget-form-group .widget-form-select .widget-form-select__indicator{
    color: ${({ theme }) => theme.mainColor};
}
.widget-form-group .widget-form-tems::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.mainColor};
}
.customer-toolbar img:not(.not-change-color){
     filter: ${({ theme }) => theme.imagesFilter};
} 
.widget-button .menu-control-btn{
    background-color: ${({ theme }) => theme.body};
}
.widget-form-group .info-block .info-message{
    border-color: ${({ theme }) => theme.mainColor};
}
.widget-form-group .widget-form-checkbox-block input + .widget-form-checkbox-styled{
    border-color: ${({ theme }) => theme.checkboxColor};    
}
.widget-form-group .widget-form-checkbox-block input:checked + .widget-form-checkbox-styled:after{
    color: ${({ theme }) => theme.mainColor};
}
.widget-form-group .widget-form-radio-block input:checked + .widget-form-radio-styled:after{
    background-color: ${({ theme }) => theme.mainColor};
}
.toolbar-items .toolbar-item .item-inner .item-main .preference-list .preference-item .switch input:checked + .slider:after{
    color: ${({ theme }) => theme.checkboxColor};
}
.spinner{
    background-color: ${({ theme }) => theme.body};
}
`