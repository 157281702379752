import { makeAutoObservable } from "mobx";

export default class UIStore{
    
    constructor(rootStore){
        this.rootStore = rootStore;

        this._toolbarOpened = false;
        this._toolbarExpanded = true;
        this._activeToolbarMenuItem = null;
        this._loading = false;
        this._widgetButtonIsPulse = false;
        this._calling = false;
        this._isCookiesContainerShaken = false;

        makeAutoObservable(this);
    }

    get toolbarExpanded(){
        return this._toolbarExpanded;
    }

    get activeToolbarMenuItem(){
        return this._activeToolbarMenuItem;
    }

    get loading(){
        return this._loading;
    }

    get toolbarOpened(){
        return this._toolbarOpened;
    }

    get widgetButtonIsPulse(){
        return this._widgetButtonIsPulse;
    }

    get calling(){
        return this._calling;
    }

    get isCookiesContainerShaken(){
        return this._isCookiesContainerShaken;
    }

    setToolbarOpened(isOpened){
        this._toolbarOpened = isOpened;
    }

    switchToolbar(expanded){
        this._toolbarExpanded = expanded;
    }

    setActiveToolbarMenuItem(item){
        this._activeToolbarMenuItem = item;
    }

    setLoading(isLoading){
        this._loading = isLoading;
    }

    setWidgetButtonIsPulse(isPulsed){
        this._widgetButtonIsPulse = isPulsed;
    }

    setCalling(isCalling){
        this._calling = isCalling;
    }

    setIsCookiesContainerShaken(isShaken){
        this._isCookiesContainerShaken = isShaken;
    }

    shakeCookiesContainer(){
        console.log('shaken in store');
        this.setIsCookiesContainerShaken(true)

        setTimeout(()=>this.setIsCookiesContainerShaken(false),1500);
    }
}