import React, {useState, useContext} from 'react';
import { ToolbarItem } from '../../cc-components'
import { withNotify, withToolbarSwitcher } from '../../hoc-helpers';
import EditProfileForm from '../forms/edit-profile-form';
import './customer-profile-item.css';
import {AppContext} from './../../../context';
import { observer } from 'mobx-react-lite';
import ProfileDetails from './profile-details';
import { animateScroll } from "react-scroll";
import { AUTH_MAIN_DOMAIN } from './../../../config';
import { CSM_MENU_ITEM_CUSTOMER_PROFILE } from './../../../utils/csm-menu-items'
import {withSpinner} from './../../hoc-helpers';
import {    
    PROFILE_STAGE_DETAILS,
    PROFILE_STAGE_EDIT  
} from '../../../utils/profile-stages';

function CustomerProfileItem ({
    activeToolbarMenuItem, 
    switchActiveToolbarItem,
    setLoading,
    showNotify,
}) {
    

    const{customerStore} = useContext(AppContext)
    
    const [profileStage, setProfileStage] = useState(PROFILE_STAGE_DETAILS)

    const logOut = () => {
        // customerStore.logOut();
        const logoutEvent = new Event('user-logout')
        document.dispatchEvent(logoutEvent);

        const refreshPageOnLOgout = window.ebsiChatConfig?.refreshOnLogout ?? true;

        if(refreshPageOnLOgout){
            window.location.href = `${AUTH_MAIN_DOMAIN}/Home/LogOut?redirectTo=${window.location.origin}`;
        }
        else{
            setLoading(true);
        }
        
    }

    const onProfileStageChanged = (profileStage) => {        
        setProfileStage(profileStage);        
        animateScroll.scrollToTop({
            containerId: "customer-profile-item",
            duration: 0
        });
    }

    const onSuccessEdit = (customerData) => {
        onProfileStageChanged(PROFILE_STAGE_DETAILS);
        showNotify(true, 'Your profile has been successfully updated. How does it look?')
        customerStore.setCustomerData(customerData);
    }

    let componentClassName = 'customer-profile-item toolbar-item';
    if(activeToolbarMenuItem === CSM_MENU_ITEM_CUSTOMER_PROFILE){
        componentClassName += ' active';
    }

    return (
        <div className={componentClassName} id='customer-profile-item'>
            <ToolbarItem itemId='csm-customer-profile' image="images/customer-profile-icon.png" onBackButtonClick={switchActiveToolbarItem} title="Your Profile">
                {profileStage===PROFILE_STAGE_DETAILS && <ProfileDetails customer={customerStore.customerData} onEditButtonClick={onProfileStageChanged} onLogOutClick={logOut} switchActiveToolbarItem={switchActiveToolbarItem}/>}
                {profileStage===PROFILE_STAGE_EDIT && <EditProfileForm customer={customerStore.customerData} onCancelButtonClick={onProfileStageChanged} onSuccessEdit={onSuccessEdit}/>}
            </ToolbarItem>
        </div>
    )
}

export default withToolbarSwitcher()(withSpinner('100px', '100px', '100px')(withNotify()(observer(CustomerProfileItem))))
