import CustomerStore from "./customer-store";
import PrivacyPolicyStore from "./privacy-policy-store"
import UIStore from "./ui-store"
import ChatStore from './chat-store';

export default class RootStore {

    constructor(){
        this.privacyPolicyStore = new PrivacyPolicyStore(this)
        this.uiStore = new UIStore(this);
        this.customerStore = new CustomerStore(this);
        this.chatStore = new ChatStore(this);
    }
}