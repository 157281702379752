import React, {useState, useContext, useEffect} from 'react';
import ToolbarItemHeader from '../toolbar-item-header';
import { withToolbarSwitcher } from '../../hoc-helpers';
import {AppContext} from './../../../context';
import './communications-preferences-item.css';
import {withSpinner} from '../../hoc-helpers';
import withNotify from '../../hoc-helpers/with-notify';
import {CommunicationPreferencesList} from './../../cc-components';
import { 
    COMMUNICATION_PREFERENCES_CUSTOMER,
    COMMUNICATION_PREFERENCES_CONSUMER
} from '../../../config';
import Select from 'react-select';
import ToolbarItem from './../../cc-components/toolbar-item';
import {
    CSM_MENU_ITEM_COMMUNICATIONS_PREFERENCES,
    CSM_MENU_ITEM_PRIVACY_SETTINGS
} from './../../../utils/csm-menu-items'
import PrcFooter from '../prc-footer';

function CommunicationsPreferencesItem ({
    activeToolbarMenuItem,
    switchActiveToolbarItem,
    setLoading,
    showNotify
}) {
    
    let componentClassName = 'communications-preferences-item toolbar-item';
    if(activeToolbarMenuItem === CSM_MENU_ITEM_COMMUNICATIONS_PREFERENCES){
        componentClassName += ' active';
    }

    const {customerStore, customerCenterService} = useContext(AppContext);
    const [emailPreferences, setEmailPreferences] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState(null)
    const [activeSiteValue, setActiveSiteValue] = useState('emergenttravelhealth.com');
    const [communicationPreferences, setCommunicationPreferences] = useState(COMMUNICATION_PREFERENCES_CUSTOMER);
    
    useEffect(() => {
        if(window.ebsiChatConfig.csmType ==='consumer'){
            setCommunicationPreferences(COMMUNICATION_PREFERENCES_CONSUMER);
            setActiveSiteValue('travelhealthconnect.com');
        }
      }, []);
    
    const defaultCommunicationPreferencesSite = communicationPreferences.find(cp => cp.value == activeSiteValue) || null;

    const userEmail = customerStore.userCommunicationPreferenceEmail

    const onChangePreferencesSite = (option) => {        
        setActiveSiteValue(option.value);
    }
    const getMailchimpSubscriptions = (userEmail, audienceIdList) => {      
        if(activeToolbarMenuItem === CSM_MENU_ITEM_COMMUNICATIONS_PREFERENCES){
            setLoading(true);
        }
        customerCenterService
        .searchMailchimpSubscription(userEmail, audienceIdList)
        .then(res => { 
            if(res){
                let mappedMailchimpMember = _mapMailchimpAudienceList(res.data.member);
                setEmailPreferences(mappedMailchimpMember);
                console.log('Mailchimp mapped data:', res.data);                        
            }
            setLoading(false);
        })
    };   
    
    const onChangeEmailItemSubscribe = (audienceId, subscribed, manualUnsubscribed) => {   
        
        if(!manualUnsubscribed){
            
            setLoading(true);

            let audienceIdList = getActiveSiteAudienceIdList();
            let tags = Array(1).fill(window.location.hostname); 

            customerCenterService
            .setMailchimpSubscription(userEmail, audienceId, subscribed, tags)
            .then(res => { 
                if(res && res.data.success){
                    getMailchimpSubscriptions(userEmail, audienceIdList);  
                    showModalUpdateCommunicationPreferences();                  
                }  
                else{
                    showNotify(false, res.data.message);
                }
            })
            .finally(()=>{
                setLoading(false);
            });
        }
    };

    const onChangeEmailListSubscribe = (subscribed) => {       
        setLoading(true);

        let audienceIdList = getActiveSiteAudienceIdList();
        
        let subscriptions = emailPreferences.map(preference=> {
            return {
              audienceId: preference.audienceId,
              subscribed: subscribed,
              manualUnsubscribed: preference.manualUnsubscribed,
              tags: Array(1).fill(window.location.hostname)
            }
          });     

        customerCenterService
        .setMailchimpSubscriptions(userEmail, subscriptions)
        .then(res => { 
            if(res && res.data.success){
                getMailchimpSubscriptions(userEmail, audienceIdList);  
                showModalUpdateCommunicationPreferences();                
            }  
            else{
                showNotify(false, res.data.message);
            }           
        })
        .catch(err => {
            showNotify(false, 'Sorry, it seems there is a problem with update. Please, try again later.')
        })
        .finally(()=>{
            setLoading(false);
        });        
    };

    const showModalUpdateCommunicationPreferences = () => { 
        setShowModal(true)
        const content = <div className='csm-modal-details'>
                            <p className='font-bold'>Your communication preferences have been updated.</p>
                            <button className="std-button std-button-middle" onClick={() => setShowModal(false)}>Okay</button>
                        </div>
        setModalContent(content)
     }
    
    const getActiveSiteAudienceIdList = () =>
    {        
        let activeSitePreferences = communicationPreferences.find(pref => pref.value == activeSiteValue);
        let activeSiteAudienceIdList = activeSitePreferences.audiences.map((audience) => { return  audience.id});

        return activeSiteAudienceIdList;
    }

    useEffect(() => {
        if(userEmail){
            let activeSiteAudienceIdList = getActiveSiteAudienceIdList();
            getMailchimpSubscriptions(userEmail, activeSiteAudienceIdList);
        }
        
    }, [userEmail]);

    useEffect(() => {   
        if(activeSiteValue && userEmail){
            let activeSiteAudienceIdList = getActiveSiteAudienceIdList();
            getMailchimpSubscriptions(userEmail, activeSiteAudienceIdList);
        }        
    }, [activeSiteValue]);

    const _mapMailchimpAudienceList = (responseData) => {        
        let activeSitePreferences = communicationPreferences.find(pref => pref.value == activeSiteValue);

        let mappedMember = responseData.map(responseMember =>{
            let container = {};

            container.audienceId = responseMember.audienceId;
            container.subscribed = responseMember.subscribed;
            container.manualUnsubscribed = responseMember.manualUnsubscribed;

            container.listTitle = activeSitePreferences.audiences.find(c=> c.id === responseMember.audienceId).listTitle;
            container.subscribeLink = activeSitePreferences.audiences.find(c=> c.id === responseMember.audienceId).subscribeLink;

            return container;
        })
        return mappedMember;
    };


    return (
        <div className={componentClassName}>
            <ToolbarItem itemId='csm-communication-preferences' image="images/customer-profile-icon.png" onBackButtonClick={switchActiveToolbarItem} title={'Communications Preferences'} showModal={showModal} modalContent={modalContent} setShowModal={setShowModal}>
            {/* <ToolbarItemHeader image="images/communications-icon.png" onBackButtonClick={switchActiveToolbarItem}>
                Communications Preferences
            </ToolbarItemHeader> */}            
            <div className='stage-body body-with-prc-footer'>
                <div className='item-subtitle font-bold'>
                    Please select your communication preferences for:
                </div>
                <div className='widget-form-group'>
                    <Select
                        name="communication_preferences_site"
                        options={communicationPreferences}                        
                        menuPlacement='bottom'     
                        defaultValue={defaultCommunicationPreferencesSite}                  
                        className="widget-form-select"
                        classNamePrefix="widget-form-select"       
                        onChange={onChangePreferencesSite}                 
                    />
                </div>
                <hr className='separator-solid'/>                        
                <CommunicationPreferencesList 
                    emailPreferences={emailPreferences} 
                    onChangeEmailItemSubscribe={onChangeEmailItemSubscribe}
                    onChangeEmailListSubscribe={onChangeEmailListSubscribe}
                />                        
                <div className='item-subtitle font-bold'>
                    If you wish to suppress tracking or delete your profile information, please review your <span className='std-link' onClick={() => switchActiveToolbarItem(CSM_MENU_ITEM_PRIVACY_SETTINGS)}>privacy settings</span>.
                </div>
                <PrcFooter absolute={true}/>                  
            </div>
            </ToolbarItem>
        </div>
    )
}

export default withToolbarSwitcher()(withNotify()(withSpinner('100px', '100px', '150px')(CommunicationsPreferencesItem)))
