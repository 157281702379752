import React, { useContext } from 'react';
import { CONTACT_STAGE_MAIN_MENU } from './../../../utils/contact-stages';
import { observer } from 'mobx-react-lite';
import PrcFooter from '../prc-footer';
import { AppContext } from '../../../context';

const ContactUsMenu = ({ 
    openMslChat,
    openRepsSelection,
}) => {
    
    const {chatStore} = useContext(AppContext)

    return (
        <div className={`contact-us-stage csm-stage contact-us-menu ${chatStore.contactStage === CONTACT_STAGE_MAIN_MENU ? 'active-stage' : 'inactive-stage'}`}>
            <div className='stage-body body-with-prc-footer'>
                <div className='contact-stage-info border-btm'>
                    <span className='font-bold'>Need help? Have questions?</span>
                    <br/>
                    We’re here for you.
                </div>
                <div className="contact-us-list">
                    <div className="contact-us-single border-btm contact-us-single-call">
                        <div className="contact-us-single-header">
                            <span>Phone Call</span>
                        </div>
                        <div className="contact-us-single-body">
                            Our Customer Success Team is here Monday to Friday, 8 AM - 8 PM EST.
                        </div>
                        <div className="contact-us-single-footer">
                            <button className="std-button contact-us-btn" onClick={()=>openRepsSelection('chat','call')}>Call Us</button>
                        </div>
                    </div>
                    <div className="contact-us-single border-btm">
                        <div className="contact-us-single-header">
                            <span>Live Online Chat</span>
                        </div>
                        <div className="contact-us-single-body">
                            Start a dialogue with our Customer Success Team, Monday to Friday, <br/> 8 AM-8 PM EST.
                        </div>
                        <div className="contact-us-single-footer">
                            <button className="std-button contact-us-btn" onClick={()=>openRepsSelection('chat','web')}>Chat Now</button>
                        </div>
                    </div>
                    <div className="contact-us-single border-btm">
                        <div className="contact-us-single-header">
                            <span>E-mail</span>
                        </div>
                        <div className="contact-us-single-body">
                            Drop us an email anytime, and we’ll get back to you soon.
                        </div>
                        <div className="contact-us-single-footer">
                            <button className="std-button contact-us-btn" onClick={()=>openRepsSelection('email')}>Email Us</button>
                        </div>
                    </div>
                    <div className="contact-us-single contact-msl">
                        <div className="contact-us-single-header">
                            <span>Reach Your MSL</span>
                        </div>
                        <div className="contact-us-single-body">
                            Call to schedule time with your MSL <br/> or email them.
                        </div>
                        <div className="contact-us-single-footer">                        
                            <a href="tel:18001234567" className="std-button contact-us-btn">Call</a>
                            <button
                                className="std-button contact-us-btn"
                                onClick={openMslChat}>
                                    E-mail
                            </button>  
                        </div>
                    </div>
                </div>
                <PrcFooter absolute={true}/>
            </div>
        </div>
        
    )
}

export default observer(ContactUsMenu);
