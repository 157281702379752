export const widgetThemes = {
    lightTheme: {
        body: '#FFF',
        itemHeaderColor: '#FFF',
        text: '#363537'
    },
    darkTheme: {    
        body: '#363537',
        itemHeaderColor: '#363537',
        text: '#FAFAFA'    
    },
    defaultTheme: {
        body: '#FFF',
        mainColor: '#4A90E2',
        itemHeaderColor: '#000000',
        imagesFilter: 'unset',
        checkboxColor: '#164161',
        widgetButtonHover: '#eff3f5',
    },
    greenTheme: {
        body: '#FFF',
        mainColor: '#42B02B',
        itemHeaderColor: '#42B02B',
        imagesFilter: 'invert(46%) sepia(69%) saturate(493%) hue-rotate(64deg) brightness(107%) contrast(96%);'
    },
    ethTheme: {
        body: '#F5FAFE',
        mainColor: '#B04A33',
        itemHeaderColor: '#000000',
        imagesFilter: 'unset',
        checkboxColor: '#164161',
        widgetButtonHover: '#eff3f5',        
    }
}