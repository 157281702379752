import React, {useContext, useEffect, useRef, useState} from 'react'
import { AppContext } from '../../../context'
import { CONTACT_STAGE_WEB_CHAT } from './../../../utils/contact-stages';
import { Client } from '@twilio/conversations';
import { animateScroll } from "react-scroll";
import MessageList from '../message-list'
import { observer } from 'mobx-react-lite';
import MessageInput from './../message-input';
import {DEBUG_MESSAGES, IP_CALL_NUMBER} from '../../../config';
import { Device } from '@twilio/voice-sdk';

import './user-chat.css'
import {
    processMessage,
} from './../../../utils/chat-helper';
import MessageLoader from '../message-loader/message-loader';
import SessionDataHandler from './../../../utils/sessionDataHandler';
import { generateUniqueId, millisecondsToHmsDisplay, download } from '../../../utils/functions';

function UserChat({
    chatType,
    onStartOverChat,
}) {
    const {chatService, customerCenterService, chatStore, customerStore, uiStore} = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(false)
    //const [callDateStarted, setCallDateStarted] = useState(new Date());
    const [isPhoneCalling, setIsPhoneCalling] = useState(false);
    const [phoneDevice, setPhoneDevice] = useState(null); 
    const [isInputActive, setIsInputActive] = useState(true);

    const conversationRef = useRef()
    const currentIdentityRef = useRef()

    const sessionDataHandler = new SessionDataHandler(chatService)

    let callDateStarted = null;

    const isUserAuthorized = customerStore.customerData.userId !== null;

    const noAvailableAgentsMessage = chatStore.representativeType !== 'any' && isUserAuthorized ? 'There is no available representatives of choosen type. Please, try again later or chose another representatives.' : 'We\'re sorry. There are no representatives available. Please call us at 1-800-533-5899, or try again later.'
    
    useEffect(() => {
        async function checkExistingConversation() {

            const data = sessionDataHandler.tryResumeExistingSession();        
            if (data) {
                try {
                    console.log("con", data.conversationSid);
                    const conversation = await initSession(data.token, data.conversationSid);
                    console.log('conv after init from local storage', conversation);
                    if(conversation.state.current === 'active'){
                        //Check if flow was finished in last message
                        const flowWasFinished = await checkFlowFinished(conversation);
                        if(!flowWasFinished){
                            chatStore.setActiveChatSession('web');
                            chatStore.setActiveSessionRepresentativeType('web');
                            // conversationRef.current = conversation;
                            // configureConversationEvents(conversationRef.current);
                            await loadChatHistory(conversation);
                        }
                    }
                } catch (e) {
                    // if initSession fails
                    console.log('Cannot init session from localstorage:', e);
                }
            } 
        }
    
        checkExistingConversation()
    }, [])

    useEffect(() => {

        if(chatStore.contactStage === CONTACT_STAGE_WEB_CHAT){

            //If there is no active session
            if(chatStore.activeChatSession === null)
            {
                //If there is no active session and it is not a product order request
                if(chatStore.orderProductName === null){
                    if(chatType === 'web'){
                        startWebChatSession()
                    }else{
                        startCallChatSession()
                    }
                }
                //If there is no active session and it is a new product order request
                else{
                    console.log(`There is no session and I want to order a pruduct: ${chatStore.orderProductName}`)
                    initProductOrdering({activeSession: false})
                }
                
            }
            //The active session already exists and it is a new product order request
            else if(chatStore.orderProductName !== null){
                console.log(`The session already exists but I want to order a pruduct: ${chatStore.orderProductName}`)
                initProductOrdering({activeSession: true})
            }
        }


    }, [chatStore.contactStage, chatStore.orderProductName])
    
    const startCallChatSession = (showRepsTypeMessage = true) => {
        console.log('start call')
        chatStore.setActiveChatSession('call');
        chatStore.setActiveSessionRepresentativeType('call');

        const {representativeType} = chatStore;

        if(representativeType !== 'any' && showRepsTypeMessage){
            const msg = representativeType === 'customerCare' ? 'CUSTOMER CARE' : representativeType.toUpperCase();
            addMessage({body: msg, isUserMsg: true});
        }
        

        checkForAgents(representativeType !== 'any' ? representativeType : null)
        .then(res => {
            console.log('reps request result', res)
            if(!res.success){
                addMessage({body: noAvailableAgentsMessage});
                chatStore.setActiveChatSession(null);
                chatStore.setActiveSessionRepresentativeType(null);
            }
            else{
                addMessage({body: `Hold on, we're connecting you to a representative`});
                loadAndStartUserChat(true, `system_call_with_${representativeType}`)
                setIsInputActive(false);
                setIsLoading(true);
            }            
        })

    }

    const startWebChatSession = (showRepsTypeMessage = true) => {
        console.log('start web')
        
        const {representativeType} = chatStore;

        if(representativeType !== 'any' && showRepsTypeMessage){
            const msg = representativeType === 'customerCare' ? 'CUSTOMER CARE' : representativeType.toUpperCase();
            addMessage({body: msg, isUserMsg: true});
        }
        
        if(representativeType !== 'medical'){

            checkForAgents(representativeType !== 'any' ? representativeType : null)
            .then(res => {
                console.log('reps request result', res)
                if(!res.success){
                    addMessage({body: noAvailableAgentsMessage});
                    chatStore.setActiveChatSession(null);
                    chatStore.setActiveSessionRepresentativeType(null);
                }
                else{
                    chatStore.setActiveChatSession('web');
                    chatStore.setActiveSessionRepresentativeType('web');
                    addMessage({body: `Hold on, we're connecting you to a representative`});
                    // handleNewMessage({body: `system_chat_with_${representativeType}`})
                    loadAndStartUserChat(true, `system_chat_with_${representativeType}`)

                }
            })
        }
        else{
            chatStore.setActiveChatSession('web');
            chatStore.setActiveSessionRepresentativeType('web');
            loadAndStartUserChat(true, `system_chat_with_${representativeType}`)
        }

    }

    const checkForAgents = async (type) => {
        const repsResult = customerCenterService.checkAvailableRepresentative(type);
        return repsResult;
    }

    const initProductOrdering = (currentData) => {
        const {activeSession} = currentData;

        if(!activeSession){
            addMessage({ type: "text", body:"Ok, let me help you with that.", isBodyHtml: false, isUserMsg: false, delay: 1000, lastInQueue: false});
            addMessage({ type: "text", body:`We'll connect you to a representative who will help place your order for ${chatStore.orderProductName} now.`, isBodyHtml: false, isUserMsg: false, delay: 3000, lastInQueue: false});

            startOrderCall();
            
        }
        else{
            addMessage({ type: "text", body:"Ok, let me help you with that.", isBodyHtml: false, isUserMsg: false, delay: 1000, lastInQueue: false});
            addMessage({ type: "text", body:`Would you like to end your current support session so we can place a new order for ${chatStore.orderProductName}?`, isBodyHtml: true, buttons:[{label: 'Yes', value: 'break_session_and_order', type:"Message"}, {label: 'No', value: 'resume_session_without_order', type:"Message"}], isUserMsg: false, delay: 3000, lastInQueue: true});
        }
    }

    const startOrderCall = () => {
        chatStore.setActiveChatSession('call');

        //Set current product name to NULL to handle future product in useEffect
        chatStore.setOrderProductName(null);
        
        const orderingProcessRepsType = 'customerCare';
        chatStore.setRepresentativeType(orderingProcessRepsType);

        loadAndStartUserChat(true, `system_call_with_${orderingProcessRepsType}`)
        setIsInputActive(false);
        setIsLoading(true);
    }

    const loadAndStartUserChat = (newConversation = false, startPhrase = 'system_new_flow') => {
        startNewChat(newConversation)
            .then(conversation => {
                handleNewMessage({body: startPhrase});
            });
        
    }

    const startNewChat = async (newConversation) => {

        const chatUserFriendlyName = customerStore.getCustomerName() || "Unknown Customer";
        let conversation;
        try{
            const newSessionData = await sessionDataHandler.fetchAndStoreNewSession(chatUserFriendlyName);
            currentIdentityRef.current = newSessionData.identity;
            console.log('new session data', newSessionData)
            conversation = await initSession(newSessionData.token, newSessionData.conversationSid);

        }
        catch(error){
            console.log(error);
            addMessage({ body: `Could not init new chat session.` })
        }

        return conversation;
    }

    const initSession = async (token, conversationSid) => {
        const conversationsClient = await Client.create(token);
        const conversation = await conversationsClient.getConversationBySid(conversationSid);
        conversationRef.current = conversation;
        const participants = await conversation.getParticipants();
        const users = await Promise.all(participants.map(async (p) => p.getUser()));
        currentIdentityRef.current = conversationsClient?.user.identity;
        
        initClientListeners(conversationsClient)
        configureConversationEvents(conversation)

        return conversation;
    }

    const initClientListeners = (conversationClient) => {
        const tokenAboutToExpireEvent = "tokenAboutToExpire";
        const connectionStateChangedEvent = "connectionStateChanged";
        const tokenExpiredEvent = "tokenExpired";

        // remove any other refresh handler added before and add it again
        conversationClient.removeAllListeners(tokenAboutToExpireEvent);
        conversationClient.addListener(tokenAboutToExpireEvent, async (message) => {
            console.log("conversationClientListener: token about to expire: ", message);

            const data = await sessionDataHandler.getUpdatedToken();
            if (data?.token && data?.conversationSid) {
                console.log('update token data from function', data)
                await conversationClient.updateToken(data.token);
            }
        });

        conversationClient.removeAllListeners(connectionStateChangedEvent);
        conversationClient.addListener(connectionStateChangedEvent, (connectionStatus) => {
            console.log('Connection status changed: ', connectionStatus)
        });

        conversationClient.removeAllListeners(tokenExpiredEvent);
        conversationClient.addListener(tokenExpiredEvent, () => {
            console.log('Token expired');
        });
    };

    const configureConversationEvents = (conversation) => {
        
        console.log('configuring...', conversation);

        conversation.on('messageAdded', (message) => {
            console.log(message);
            const { author, body, state: { sid, timestamp, index } } = message;
            // if(this.state.firstMessageIndex === null){
            //     this.setState({firstMessageIndex: index});
            // }

            const messageDate = new Date(Date.parse(timestamp));
            const processedMessage = processMessage(sid, author, body, messageDate, currentIdentityRef.current)
            addMessage(processedMessage);
        })

        conversation.on('updated', ({conversation, updateReasons}) => {
            console.log('conversation updated', conversation);
            console.log('conv state', conversation.state);
            console.log('reasons', updateReasons);
            if(conversation.state.current !== 'active'){
                
                showStartOverMessage(); 
                chatStore.setActiveChatSession(null);
                chatStore.setActiveSessionRepresentativeType(null);
                conversationRef.current = null;
            }
            
        })
    }

    const endCurrentSession = () => {
        if(chatStore.activeChatSession === 'call'){
            onVoiceCallEnd();
        }
        chatStore.setActiveChatSession(null);
        conversationRef.current.removeAllListeners();
        conversationRef.current = null;
    }
    
    const finishFlowExecution = async (chatFlow) => {
        return await chatService.finishFlowExecution(conversationRef.current.sid, chatFlow);
    }

    const handleNewMessage = ({body}) => {
        if (conversationRef.current) {
            conversationRef.current.sendMessage(body)
        }
    }

    const showStartOverMessage = () => {
        const buttonLabel = chatType === 'web' ? 'Start New Chat' : 'Start New Call'
        const buttonValue = chatType === 'web' ? `system_chat_with_${chatStore.representativeType}` : `system_call_with_${chatStore.representativeType}`
        addMessage({ type: "start_over", body:"Thank you for communicating with us! <br> If you have any more questions please reach out to us again.", isBodyHtml: true, buttons:[{label: buttonLabel, value: buttonValue, type:"Message"}], isUserMsg: false, delay: 1000, lastInQueue: true});
        // this.scrollMessageContainer(500);
    }

    const onStartOverButtonClick = (sid, title, value = '') => {
        clearMessage(sid);
        finishFlowExecution('csm-chat')
                .then(res => {
                    if(conversationRef.current)
                        conversationRef.current.removeAllListeners();
                })
                .then(res => {
                    onStartOverChat(chatType);
                })
                .catch((error) => {
                    console.log(error);
                    //addMessage({ body: `Error: ${error.message}` })
                    onStartOverChat(chatType);
                })
    }

    const onMessageButtonClick = (sid, title, value = '') => {
        console.log('message button')

        clearMessage(sid);

        if(value === 'break_session_and_order')
        {
            endCurrentSession();
            addMessage({ type: "text", body:"Let me connect you with a representative who can take care of that for you.", isBodyHtml: false, isUserMsg: false, delay: 2000, lastInQueue: true});
            startOrderCall();


        }
        else if(value === 'resume_session_without_order'){
            addMessage({ type: "text", body:"Ok, we'll keep your current session active. You can always start a new support session to have us process your request.", isBodyHtml: false, isUserMsg: false, delay: 1000, lastInQueue: true});
        }
        else{
            const buttonMsgBody = {
                body: title,
                value: value
            }
            console.log('button value-',buttonMsgBody);
            handleNewMessage({body: JSON.stringify(buttonMsgBody)});
        }

    }

    const onMessageLinkClick = (sid, title, value = '', continueFlow = null) => {
        console.log('continue flow', continueFlow)
        if(continueFlow){
            clearMessage(sid);
            const buttonMsgBody = {
                body: title,
                value: continueFlow
            }
            console.log('button value-',buttonMsgBody);
            handleNewMessage({body: JSON.stringify(buttonMsgBody)});
        }
    }

    const scrollMessageContainer = (duration) =>{
        // animateScroll.scrollToBottom({
        //     containerId: "messages",
        //     duration: 50
        // });
    }

    const addMessage = (message) => {
        if(typeof(message.sid) === 'undefined' || message.sid === null){
            message.sid = generateUniqueId(15);
        }
        message.messageDate = message.messageDate ? message.messageDate : new Date();

        if(message.type === "start_over"){
            message.onButtonClick = onStartOverButtonClick; 
        }
        else{
            message.onButtonClick = onMessageButtonClick;
        }

        message.onMessageLinkClick = onMessageLinkClick;

        if(typeof(message.isUserMsg) === 'undefined'){
            message.isUserMsg = false;
        }

        if(message.type === 'segment_identity'){
            window.analytics.identify(message.email, message.segmentData);
        }
        else if(message.type === 'phone_call'){           
            loadVoiceChat(message.pinCode);
        }
        else if(message.type === 'download_file'){
            download('Mirf.pdf', message.body);
        }
        else if(message.type === 'open_email'){
            window.location.href = `mailto:${message.body}`;
        }
        else if(message.type === 'open_tel'){
            window.location.href = `tel:${message.body}`;
        }
        else if(!message.body.startsWith('system_')){

            if(message.delay){
                setIsLoading(true)
                
                setTimeout(() => {
                    setIsLoading(!message.lastInQueue)
                    // setMessages((prevMessages)=>{
                    //     return [...prevMessages, message];
                    // })
                    chatStore.addMessage(message);
                    scrollMessageContainer(1000);
                },message.delay);
            }
            else{

                // setMessages((prevMessages)=>{
                //     return [...prevMessages, message];
                // })
                chatStore.addMessage(message);
    
                let showLoading = false;
                if(message.showLoading === true){
                    showLoading = true;
                }
                setIsLoading(showLoading)
                
                setTimeout(() => {                    
                    scrollMessageContainer(500);
                },message.delay);               
            }
        }
    }

    const checkFlowFinished = async (conversation) => {

        let flowWasFinished = false; 
        const history = await conversation.getMessages(1);
        if(history.items.length > 0){
            const lastMessage = history.items[0];
            const {sid, timestamp, author, body} = lastMessage.state;
            const msgDate = new Date(timestamp)
            const processedMsg = processMessage(sid,author, body, msgDate, currentIdentityRef.current);
            if(processedMsg.type === "start_over"){
                flowWasFinished = true;
            }
        }
        return flowWasFinished;
    }

    const loadChatHistory = async (conversation) => {
        const history = await conversation.getMessages(10);
        console.log('history',history.items);

        history.items.forEach((message, index) => {
            try{
                const {sid, timestamp, author, body} = message.state;
                const msgDate = new Date(timestamp)
                const processedMsg = processMessage(sid,author, body, msgDate, currentIdentityRef.current);
                if(index != history.items.length-1){
                    processedMsg.buttons = null;
                    processedMsg.options = null;
                    processedMsg.isMulti = false;
                }

                console.log('processed msg',processedMsg)
                if(processedMsg.type !== 'phone_call'){
                    if(processedMsg.type !== 'download_file'
                        && processedMsg.type !== 'open_email'
                        && processedMsg.type !== 'open_tel')
                    {
                        addMessage(processedMsg);
                    }
                    
                }
                else{
                    chatStore.setActiveChatSession(null);
                    chatStore.setActiveSessionRepresentativeType(null);
                }
                
            }
            catch(ex){
                console.log('Cannot add message from history:', ex)
            }

        });

    }

    const clearMessage = (messageId) => {
        // setMessages(previousMessages =>{
        //     const messages = previousMessages.map((msg) => {
        //         if(msg.sid === messageId){
        //             msg.buttons = null;
        //             msg.options = null;
        //         }
        //         return msg;
        //     });

        //     return messages;
        // })
        chatStore.clearMessage(messageId);
    }

    const loadVoiceChat = (pinCode, newChannel = false) => {
        const data = sessionDataHandler.tryResumeExistingSession(); 
        try{
                
            const device = new Device(data.token, {                
                codecPreferences: ["opus", "pcmu"],                
                fakeLocalDTMF: true,                
                enableRingingState: true
            });

            var params = {
                To: IP_CALL_NUMBER
            };
            if(DEBUG_MESSAGES){
                console.log("Calling " + params.To + "...");
            }                                     
                
            if (device) {
                device.connect({params})
                    .then(call => {

                        call.on('accept', call => {
                            callDateStarted = new Date();     
                            uiStore.setCalling(true);     
                            setIsLoading(false);

                            console.log('callDateStarted: ', callDateStarted);

                            animateScroll.scrollToBottom({
                                containerId: "messages",
                                duration: 1000
                            });
                            setTimeout(()=>{
                                call.sendDigits(pinCode.toString())
                            }, 1000)
                            
                        })

                        call.on('disconnect', call => {
                            if(DEBUG_MESSAGES){
                                console.log("Disconnect...");
                            }

                            const callDateStartedTimeFormat = new Intl.DateTimeFormat('en-US', {hour: '2-digit', minute: '2-digit'}).format(callDateStarted);                    
                            const callDateEnded = new Date();
                            const callDuration = callDateEnded - callDateStarted;
                            const callDurationTimeFormat = millisecondsToHmsDisplay(callDuration);
                            
                            let callEndedMessage = `<p>Call Ended.</p><p>${callDurationTimeFormat}, ${callDateStartedTimeFormat}</p>`;
                            
                            if(!uiStore.calling){
                                callEndedMessage = `<p>Oops... Something wrong with your connection.</p>`;
                            }
                            
                            uiStore.setCalling(false);  
                            setIsInputActive(true);
                            setIsLoading(false);

                            const message = {      
                                type: "voice_message",             
                                body: callEndedMessage,
                                isBodyHtml: true,
                                isUserMsg: false,
                                showAvatar:false
                            }
                            
                            addMessage(message);
                            chatStore.setActiveChatSession(null);
                            chatStore.setActiveSessionRepresentativeType(null);
                            // showStartOverMessage(); 
                        });

                    });
                
            }

            setPhoneDevice(device);                

          }
          catch(error){
            console.error(error.message);
          }  
    }

    const onVoiceCallEnd=()=>{
        
        if(DEBUG_MESSAGES){
            console.log("Hanging up...");
        }

        if (phoneDevice) {
            phoneDevice.disconnectAll();
        }  
    }

    return (
        <div className='user-chat'>
             <div id='chat-session-button'>
                <button
                    className='std-button'
                    style={{padding: '5px 15px', fontSiza:'11px'}}
                    onClick={onStartOverButtonClick}>Start New</button>
            </div> 
            <div id='messages'>
                {isLoading && <MessageLoader/>} 
                <MessageList onVoiceCallEnd={onVoiceCallEnd} isCalling={uiStore.calling}/>            
            </div>              
            <MessageInput activeInput={isInputActive} addMessage={handleNewMessage} />
        </div>
    )
}

export default observer(UserChat)
