import { makeAutoObservable } from "mobx";
import { processCustomerData } from "../utils/functions";

export default class CustomerStore{
    
    constructor(rootStore){
        this.rootStore = rootStore;        

        this._customerData = {userId: null};
        
        this._userCommunicationPreferenceEmail = null;

        makeAutoObservable(this);
    }

    get customerData(){
        return this._customerData;
    }    

    setCustomerData(customerData){
        this._customerData = processCustomerData(customerData);
        this._userCommunicationPreferenceEmail = customerData.email || null
    }

    get userCommunicationPreferenceEmail(){
        return this._userCommunicationPreferenceEmail;
    }

    setUserCommunicationPreferenceEmail(email){
        this._userCommunicationPreferenceEmail = email;
    }

    authorizeCustomer(data){
        window.analytics.identify(data.email || data.userId, {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            speciality: data.speciality,
            phone: data.phone || '',
            address: data.address || '',
            npi: data.npi,
          });
    
          this.setCustomerData(data);
    }

    // setCommunicationPreferences(userId, preferences){
    //     window.analytics.identify(userId, {
    //         communicationPreferences: preferences
    //       });
    // }

    setCommunicationPreferences(preferences){
        this._customerData.communicationPreferences = preferences;
    }
    
    logOut(){
        window.analytics.reset();
        this.setCustomerData({userId: null});
    }

    getCustomerName(){
        let customerName = null;

        if (this._customerData.userId != null){
            customerName = `${this._customerData.first_name} ${this._customerData.last_name}`;
        }
        
        return customerName;
    }
}