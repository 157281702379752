import { makeAutoObservable } from "mobx";
import Cookies from 'universal-cookie';
import { CONTACT_STAGE_MAIN_MENU, CONTACT_STAGE_WEB_CHAT } from "../utils/contact-stages";

export default class ChatStore{
    
    constructor(rootStore){
        this.rootStore = rootStore;

        this._contactStage = CONTACT_STAGE_MAIN_MENU;
        this._representativeType = 'medical';
        this._orderProductName = null;
        this._messages = [];

        this._isChatSessionActive = false;
        this._isCallSessionActive = false;
        this._activeChatSession = null;
        this._newMessages = 0;

        makeAutoObservable(this);
    }

    get isChatSessionActive(){
        return this._isChatSessionActive;
    }

    get isCallSessionActive(){
        return this._isCallSessionActive;
    }

    get activeChatSession(){
        return this._activeChatSession;
    }

    get newMessages(){
        return this._newMessages;
    }

    get activeSessionRepresentativeType(){
        const cookies = new Cookies();
        return cookies.get('ActiveSessionRepresentativeType');
    }

    get contactStage(){
        return this._contactStage;
    }

    get representativeType(){
        return this._representativeType
    }

    get orderProductName(){
        return this._orderProductName;
    }

    get messages(){
        return this._messages;
    }

    setContactStage(stage){
        this._contactStage = stage;
    }

    setRepresentativeType(type){
        this._representativeType = type;
    }

    setOrderProductName(productName){
        this._orderProductName = productName;
    }

    setMessages(messages){
        this._messages = messages;
    }

    setIsChatSessionActive(isChatSessionActive){
        this._isChatSessionActive = isChatSessionActive;
    }

    setIsCallSessionActive(isCallSessionActive){
        this._isCallSessionActive = isCallSessionActive;
    }

    setActiveChatSession(activeChatSession){
        this._activeChatSession = activeChatSession;
    }

    setActiveSessionRepresentativeType(activeSessionRepresentativeType){
        const cookies = new Cookies();
        cookies.set('ActiveSessionRepresentativeType', activeSessionRepresentativeType, { path: '/' });
    }

    addMessage(message){
        this._messages = [...this._messages, message];
    }

    clearMessage(messageId){
        this._messages = this._messages.map((msg) => {
            if(msg.sid === messageId){
                msg.buttons = null;
                msg.options = null;
            }
            return msg;
        });
       
    }

    readNewMessages(){
        this._newMessages = 0;
    }

    onOrderProduct(productName){
        if(productName !== this._orderProductName){
            this.setContactStage(CONTACT_STAGE_WEB_CHAT)
            this.setOrderProductName(productName);
        }
    }
    
}