import React from 'react';
import { PRC_CODE } from '../../../config';
import './prc-footer.css';

function PRCFooter({
    absolute = false
}) {
    return (
        <div className={`prc-footer ${absolute ? 'footer-absolute' : 'footer-static'}`}>
            {PRC_CODE}
        </div>
    )
}

export default PRCFooter
