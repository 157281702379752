import axios from 'axios';

export default class CustomerCenterService {
    _apiBase = '';
    // $host = {}
    // _chatType = 'content';

    constructor(apiBaseUrl){
      this._apiBase = apiBaseUrl || 'https://profiles.segment.com/v1/spaces/9hAZOePdHQ/';

      this.$host = axios.create({
        baseURL: apiBaseUrl
      })

      this.$mainAuthDomain = axios.create({
        baseURL: apiBaseUrl
      })
    }

    setMainDomainUserId = async (email) => {
      const response = await this.$mainAuthDomain.get(`/Home/Authorize?userId=${email}`);
      return response;
    }

    getCustomerData = async (email, id) => {
      const response = await this.$host.get(`get-customer-data?email=${email}&id=${id}`);
      const customerData = this._getCustomerDataFromResponse(response)
      console.log('data from redshift', customerData);
      return customerData;
    }

    getNpiSearch = async(firstName, lastName, email = null, zip = null) => {
      let searchQuery = `search-customer?firstName=${firstName}&lastName=${lastName}`;
      if(email != null){
        searchQuery+= `&email=${email}`
      }
      if(zip != null){
        searchQuery +=`&zip=${zip}`
      }
      const response = await this.$host.get(searchQuery);
      if(response.data.success !== true){
        return null;
      }
      console.log('npi search', response.data)
      return this._mapCustomerFromResponse(response.data.responseObject);
    }

    getSearchResultsByNpi = async(npi, email) => {
      let searchQuery = `search-customers-npi?npi=${npi}&email=${email}`;
      const response = await this.$host.get(searchQuery);
      return response.data;
      // return {
      //   success: true,
      //   responseObject: [
      //     {id:'sdfds4234234', email:'address1@mail.com', first_name:'John', last_name: 'Doe', npi:'4573456702'},
      //     {id:'sdfds4sfsdf4234', email:'address2@mail.com', first_name:'Michael', last_name: 'Felps', npi:'96643854634'},
      //     {id:'sdfds6545fdfg4', email:'address3@mail.com', first_name:'Kate', last_name: 'Monroe', npi:'5542819588'},
      //   ]
      // }
    }

    getSearchResultsByName = async(email, firstName, lastName) => {
      let searchQuery = `search-customers-name?email=${email}&firstName=${firstName}&lastName=${lastName}`;
      const response = await this.$host.get(searchQuery);
      return response.data;
      // return {
      //   success: true,
      //   responseObject: [
      //     {id:'sdfds4234234', email:'address1@mail.com', first_name:'John', last_name: 'Doe', npi:'4573456702'},
      //     {id:'sdfds4sfsdf4234', email:'address2@mail.com', first_name:'Michael', last_name: 'Felps', npi:'96643854634'},
      //     {id:'sdfds6545fdfg4', email:'address3@mail.com', first_name:'Kate', last_name: 'Monroe', npi:'5542819588'},
      //   ]
      // }
    }

    getSearchResultsByNpiForCreating = async(npi, email) => {
      let searchQuery = `search-customers-registry-npi?npi=${npi}&email=${email}`;
      const response = await this.$host.get(searchQuery);
      return response.data;
      // return {
      //   success: true,
      //   responseObject: [
      //     {id:'sdfds4234234', email:'address1@mail.com', first_name:'John', last_name: 'Doe', npi:'4573456702'},
      //     {id:'sdfds4sfsdf4234', email:'address2@mail.com', first_name:'Michael', last_name: 'Felps', npi:'96643854634'},
      //     {id:'sdfds6545fdfg4', email:'address3@mail.com', first_name:'Kate', last_name: 'Monroe', npi:'5542819588'},
      //   ]
      // }
    }

    getSearchResultsByNameForCreating = async(email, firstName, lastName) => {
      let searchQuery = `search-customers-registry-name?email=${email}&firstName=${firstName}&lastName=${lastName}`;
      const response = await this.$host.get(searchQuery);
      return response.data;
      // return {
      //   success: true,
      //   responseObject: [
      //     {id:'sdfds4234234', email:'address1@mail.com', first_name:'John', last_name: 'Doe', npi:'4573456702'},
      //     {id:'sdfds4sfsdf4234', email:'address2@mail.com', first_name:'Michael', last_name: 'Felps', npi:'96643854634'},
      //     {id:'sdfds6545fdfg4', email:'address3@mail.com', first_name:'Kate', last_name: 'Monroe', npi:'5542819588'},
      //   ]
      // }
    }

    claimProfile = async (values) => {
      const response = await this.$host.post('claim-profile',values);
      console.log('data',response.data)
      if(response.data.success){
        response.data.customer = this._mapCustomerFromResponse(response.data.responseObject)
      }
      return response.data;
    }

    createProfile = async (values) => {
      const response = await this.$host.post('create-profile',values);
      console.log('data',response.data)
      if(response.data.success){
        response.data.customer = this._mapCustomerFromResponse(response.data.responseObject)
      }
      return response.data;
    }

    registerNewCustomer = async (email, firstName, lastName, speciality, communication_preferences) => {
      const response = await this.$host.post('new-customer-registration', {email, firstName, lastName, speciality, communication_preferences: JSON.stringify(communication_preferences)});
      return response.data;
    }

    updateCustomerData = async (id, npi, first_name, last_name, email, phone, zip, speciality) => {
      const response = await this.$host.post('update-customer', { id, npi, first_name, last_name, email, phone, zip, speciality});
      response.data.user = this._mapCustomerFromResponse(response.data.responseObject);
      return response.data;
    }

    updateCustomerCommunicationPreferences = async (id, preferences) => {
      const response = await this.$host.post('updated-customer-preferences', {id, communication_preferences: JSON.stringify(preferences)});
      return response.data;
    }

    generateAndSendVerificationCode = async (email) => {
      const response = await this.$host.post(`generate-code`, {email});
      return response.data;
    }

    searchCustomerByNpi = async (npi) => {
      const response = await this.$host.get(`search-by-npi?npi=${npi}`);
      return response.data;
    }

    searchMailchimpSubscription = async (email, audienceIdList) => {  
      let audienceIdListStr = audienceIdList.join(',');

      const response = await this.$host.get(`search-mailchimp-subscription?email=${email}&audienceIdListStr=${audienceIdListStr}`);
      //response.data.member = this._mapMailchimpIdList(response.data.member);
      return response;
    
    }

    checkAvailableRepresentative = async (skills) => {
      console.log('skills', skills)
      const request = skills !== null 
        ? () => this.$host.get(`check-available-representative?skills=${skills}`)
        : () => this.$host.get(`check-available-representative`)
      const response = await request();      
      return response.data;
    } 

    setMailchimpSubscription = async (email, audienceId, subscribed, tags) => {      
      const response = await this.$host.post(`/set-mailchimp-subscription`, {email, audienceId, subscribed, tags});
      return response;
    
    }

    setMailchimpSubscriptions = async (email, subscriptions) => {      
      const response = await this.$host.post(`/set-mailchimp-subscriptions`, {email, subscriptions});
      return response;
    
    }

    // generateAndSendVerificationCode = async (email) => {
    //   const codeResult = await this.getResource(`generate-code`, 'POST', {email});
    //   return codeResult;
    // }

    verifyUserCode = async (email, code) =>{
      const verifyingResult = await this.getResource(`verify-user-code?email=${email}&code=${code}`, 'GET', null);
      return verifyingResult;
    }

    suppressPersonasInformation = async (id) => {
      console.log('s id: ', id);
      const response = await this.$host.post(`personas/suppress-information`, `userId=${id}`);
      return response.data;
    }

    deletePersonasInformation = async (id) => {
      const response = await this.$host.post(`personas/delete-information`, `userId=${id}`);
      return response.data;
    }

    getResource = async (url, method = 'GET', requestBody = null, auth = null) => {
        let request = {
          method: method,
          headers: {
            "Content-Type": "application/json"
          }
        }

        if(auth !== null){
          request.headers.Authorization = 'Basic' + btoa(auth.username + ":" + auth.password);
        }

        if(method === 'POST' && requestBody != null){
          request.body = JSON.stringify(requestBody);
        }
        
        const response = await fetch(`${this._apiBase}${url}`, request);
        if(!response.ok){
          throw new Error(`Could not fetch ${url}
          Received: ${response.status}`)
        }
        const body = await response.json();

        return body;
      }

      _getUserTraitsFromData = (userData) => {
        //console.log(userData.traits);
        const dataObj = JSON.parse(userData);
        return {
          userId: dataObj.traits.email,
          email: dataObj.traits.email,
          first_name: dataObj.traits.first_name || dataObj.traits.firstName || '',
          last_name: dataObj.traits.last_name || dataObj.traits.lastName || '',
          speciality: dataObj.traits.speciality || '',
          phone: dataObj.traits.phone || '',
          address: dataObj.traits.address || '',
          communicationPreferences: dataObj.traits.communicationPreferences || null,
        }
      }

      _getCustomerDataFromResponse = (response) => {
        if(response.data.success !== true){
          return {};
        }

        const customerData = response.data.responseObject;
        console.log('data', customerData);

        return this._mapCustomerFromResponse(customerData);
      }

      _mapCustomerFromResponse = (customerData) => {
        return {
          userId: customerData.id,
          email: customerData.email,
          first_name: customerData.first_name || customerData.firstName || '',
          last_name: customerData.last_name || customerData.lastName || '',
          speciality: customerData.speciality || '',
          phone: customerData.phone || '',
          address: customerData.address || '',
          npi: customerData.npi || '',
          // communicationPreferences: null,
          communicationPreferences: customerData.communication_preferences ? JSON.parse(customerData.communication_preferences) : null,
          class_of_trade : customerData.class_of_trade || '',
          zip : customerData.zip || ''
        }
      }     
      
};
