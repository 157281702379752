import { ThemeProvider} from 'styled-components'
import { GlobalStyles } from '../global-styles/GlobalStyles';
import  {widgetThemes} from '../themes/Themes'

import React from 'react'

export default function ThemeProviderContainer({children, theme}) {
    return (
        <div className='theme-provider-container'>
            <ThemeProvider theme={widgetThemes[theme]}>
            <>
            <GlobalStyles/>
        
            {children}
        
            </>
            </ThemeProvider>
        </div>
    )
}
