import { useContext, useEffect } from 'react';
import AppContext from '../../context/AppContext';
import CookiesContainer from '../cookies/cookies-container';
import Cookies from 'universal-cookie';
import './app.css';
import {AUTH_MAIN_DOMAIN} from '../../config';
import { observer } from 'mobx-react-lite';
import CustomerToolbarContainer from '../customer-toolbar/customer-toolbar-container';
import WidgetButton from '../widget-button';
import {getPrivacyPolicyPreferences} from '../../utils/constants';
import ThemeProviderContainer from '../cc-components/theme-provider-container';
import { OPEN_CSM_CLASS } from './../../utils/csm-interactive';

function App() {

  const{privacyPolicyStore, customerStore, uiStore, customerCenterService} = useContext(AppContext)
  const {privacyPolicyStatus} = privacyPolicyStore;  
  // const [userId, setUserId] = useState(null);

  window.onIdentityWithEmailCallback = function(email){
    customerStore.setUserCommunicationPreferenceEmail(email)
  }

  const onGetCookiesFromIframe = (e) => {
    if(e.origin === AUTH_MAIN_DOMAIN){
      console.log('message from iframe', e);
      if(e.data.user_id !== undefined && e.data.user_id !== null && e.data.type === 'csm'){
        onChangedUserId(decodeURIComponent(e.data.user_id));
        console.log('user id from iframe',e.data.user_id);
        privacyPolicyStore.setPrivacyPolicyStatus('hidden');
      }
    }
    
  }
  
  const onChangedUserId = (id) => {
    // setUserId(id);
    loadUserData(id);
  }

  useEffect(() => {
    const cookies = new Cookies();
    const privacyStatus = cookies.get('CookiePolicy');
    privacyPolicyStore.setPrivacyPolicyStatus(privacyStatus);
    
    window.addEventListener("message", onGetCookiesFromIframe, false);

    document.addEventListener('click',function(e){
      if(e.target && e.target.classList.contains(OPEN_CSM_CLASS)){
        e.preventDefault()
        if(privacyPolicyStore.privacyPolicyStatus ==='hidden'){
          uiStore.setToolbarOpened(true);
          uiStore.setActiveToolbarMenuItem(null);
        }
        else if(privacyPolicyStore.privacyPolicyStatus === 'declined'){
          privacyPolicyStore.setPrivacyPolicyStatus(undefined);
        }
        else{
          uiStore.shakeCookiesContainer();
        }
      }
    })


       return () => {
         window.removeEventListener('message', onGetCookiesFromIframe)
       }
  }, [])

  // useEffect(() => {
  //   loadUserData();
  // }, [userId])

  useEffect(() => {
    console.log('Policy status - ', privacyPolicyStatus);
    const loadTimeout = setTimeout(() => {

      if(window.ebsiChatConfig.csmType !== 'customer'){
        loadCommuncationPreferencesEmail();
      }
      
      const preferences = loadCustomPreferences();
      privacyPolicyStore.setCustomerPrivacyPolicyPreferences(preferences);

    },1000);
    return () => {
      clearTimeout(loadTimeout);
    }
  }, [privacyPolicyStatus]);

  const loadCommuncationPreferencesEmail = () => {
    console.log(window.analytics)
    if(window.analytics && window.analytics.user){
      try{
        const email = window.analytics.user().id();
        if(email != null){
          customerStore.setUserCommunicationPreferenceEmail(email)
        }
      }
      catch(ex){
        console.log(ex)
      }
      
    }
  }

  const loadCustomPreferences = () => { 
    let privacyPolicyPreferences = getPrivacyPolicyPreferences().map((item) => {      
      return item;
    });

    if(window.consentManager){
      const consentPreferences = window.consentManager.preferences.loadPreferences();
      if(consentPreferences && consentPreferences.customPreferences){

        privacyPolicyPreferences = privacyPolicyPreferences.map((item) => {
          item.value = consentPreferences.customPreferences[item.id]
          return item;
        })
      }
    }

    return privacyPolicyPreferences;
  }

  const loadUserData = (userId) => {
    try{
      // const userId = window.analytics.user().id();
      //console.log('userId ',userId);

      if(typeof(userId) !== 'undefined' && userId !== null){
        customerCenterService
          .getCustomerData('', userId)
          .then(data => customerStore.setCustomerData(data))
          .catch(err => {
            console.error('Cannot get customer data: ', err);
          }); 
      }
    }
    catch{}
  }

  return (
    <div className="App">
      <ThemeProviderContainer theme={window.ebsiChatConfig.colorTheme}>
        <CustomerToolbarContainer/>
        <WidgetButton/>
        <CookiesContainer/>
        <iframe id='mainDomain' src={AUTH_MAIN_DOMAIN} style={{display: 'none'}} title='Main domain iframe'></iframe>
      </ThemeProviderContainer>
      
    </div>
  );
}

export default observer(App)
