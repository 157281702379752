import React from 'react'
import ChatButton from '../chat-button';

import './buttons-list.css'


function ButtonsLIst({
    messageSid,
    buttons,
    onButtonClick,
    onMessageLinkClick,
}) {
    const buttonsElements = buttons.map((button, index) => {
        
        return <ChatButton key={index} 
                    label={button.label}
                    value={button.value}
                    type={button.type}
                    target={button.target ?? '_blank'}
                    download={button.download ?? false}
                    continueFlow={button.continueFlow}
                    messageSid={messageSid}
                    onButtonClick={onButtonClick}
                    onMessageLinkClick={onMessageLinkClick}/>
    })

    return (
        <div className="buttons-container">
            {buttonsElements}
        </div>
    )
}

export default ButtonsLIst
