import React from 'react'
import NumberFormat from 'react-number-format'
import { SPECIALITIES } from '../../../config';
import PrcFooter from '../prc-footer';
import {CSM_MENU_ITEM_PRIVACY_SETTINGS} from './../../../utils/csm-menu-items'
import {   
    PROFILE_STAGE_EDIT
} from '../../../utils/profile-stages';

function ProfileDetails({customer, onEditButtonClick, onLogOutClick, switchActiveToolbarItem}) {

    const customerSpeciality = SPECIALITIES.find(sp => sp.value === customer.speciality) || null;
    
    return (
        <div className='profile-details'>
            <div className='stage-title'>Profile Details</div>
            <div className='stage-body body-with-title body-with-prc-footer'>
                <div className='profile-details-title font-bold border-btm'>Here is your current profile:</div>
                <div className='profile-details-section border-btm'>
                    <ProfileDetailsItem title="First Name" value={customer.first_name}/>
                    <ProfileDetailsItem title="Last Name" value={customer.last_name}/>
                    <ProfileDetailsItem title="Email Address" value={customer.email}/>
                    <ProfileDetailsItem title="ZIP Code" value={customer.zip}/>
                    <ProfileDetailsItem title="NPI Number" value={customer.npi}/>   
                    <ProfileDetailsItem title="Speciality" value={customerSpeciality?customerSpeciality.label:null}/>
                    <ProfileDetailsItem title="Phone Number" value={<NumberFormat value={customer.phone} displayType={'text'}  format="+# (###) ###-####"/>}/>                 
                </div>
                <div className='profile-details-section'>
                    <div className='font-bold'>How does it look?</div>
                    <span>Would you like to make changes?</span>  
                </div>             
                <div className='profile-buttons-group profile-details-section'>
                        <button className="std-button std-gray" type='button' onClick={onLogOutClick}>Logout</button>
                        <button className="std-button" type='button' onClick={() => onEditButtonClick(PROFILE_STAGE_EDIT)}>Edit My Profile</button>
                </div>
                <div>
                    If you wish to suppress or delete your profile information, please review your <span className="std-link" onClick={() => switchActiveToolbarItem(CSM_MENU_ITEM_PRIVACY_SETTINGS)}>privacy settings</span>.
                </div>
                <PrcFooter absolute={true}/>
           </div>
        </div>
    )
}

export const ProfileDetailsItem = ({title, value}) => {
    return(
        <div className={`widget-form-group`}>
            <span className='widget-form-label'><b>{title}</b></span>
            <div className='label-value'>{value ? value : '-'}</div>
        </div>
    )
}

export default ProfileDetails
