import React, {useContext, useState, useEffect} from 'react';
import ToolbarItem from '../../cc-components/toolbar-item';
import { withToolbarSwitcher } from '../../hoc-helpers';
import './privacy-settings-item.css';
import { observer } from 'mobx-react-lite';
import {AppContext} from './../../../context';
import PrivacySettingsList from './privacy-settings-list';
import PrivacyMenu from './privacy-menu';
import {withSpinner, withNotify} from '../../hoc-helpers';
import {CSM_MENU_ITEM_PRIVACY_SETTINGS} from './../../../utils/csm-menu-items'

function PrivacySettingsItem ({
    activeToolbarMenuItem,
    switchActiveToolbarItem,
    setLoading,
    showNotify
}) {

    const {privacyPolicyStore, customerStore, customerCenterService} = useContext(AppContext);
    const [stage, setStage] = useState('menu')
    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState(null)
    const [showPersonasRegulationButtons, setShowPersonasRegulationButtons] = useState(false)

    const onTogglePreferences = (preferenceId) => {
        const newPreferences = privacyPolicyStore.customerPrivacyPolicyPreferences.map(item => {
            if(item.id === preferenceId){
              item.value = !item.value;
            }
            return item;
          })
      
          privacyPolicyStore.setConsentManagerPreferences(newPreferences);
    }

    const privacyPreferences = privacyPolicyStore.customerPrivacyPolicyPreferences;       
    const userEmail = customerStore.userCommunicationPreferenceEmail;    
        
    useEffect(() => {
       if(userEmail !=null){        
            setShowPersonasRegulationButtons(true);
        }
    }, [userEmail]);

    const onSuppressInformation = (e) => { 
         e.preventDefault();
         setLoading(true);

         customerCenterService
         .suppressPersonasInformation(userEmail)
         .then(res => { 
             if(res && res.success){                 
                setShowModal(true)
                const content = <div className='csm-modal-details'>
                                    <p className='font-bold'>We will begin suppressing data collection within 24 hours.</p>
                                    <button href='#' className="std-button std-button-middle" onClick={() => setShowModal(false)}>Okay</button>
                                </div>
                setModalContent(content);
                console.log('Suppress user:', userEmail );                        
             }
             else{
                showNotify(false, 'Sorry, it seems there is a problem with suppress. Please, try again later.')
             }
         })
         .catch(err => {
            showNotify(false, 'Sorry, it seems there is a problem with suppress. Please, try again later.')
        })
         .finally(()=>{
            setLoading(false);
        });   
    }

    const onDeleteInformation = (e) => { 
        e.preventDefault();        
        setLoading(true);

        customerCenterService
        .deletePersonasInformation(userEmail)
        .then(res => { 
            if(res && res.success){   
                setShowModal(true)
                const content = <div className='csm-modal-details'>
                                    <p className='font-bold'>Your deletion request will be processed within 30 days.</p>
                                    <button href='#' className="std-button std-button-middle" onClick={() => setShowModal(false)}>Okay</button>
                                </div>
                setModalContent(content);
                console.log('Delete user:', userEmail);                        
            }
            else{
                showNotify(false, 'Sorry, it seems there is a problem with delete. Please, try again later.')
            }
        })
        .catch(err => {
            showNotify(false, 'Sorry, it seems there is a problem with delete. Please, try again later.')
        })
        .finally(()=>{
            setLoading(false);
        });   
        
   }

    let onBackButtonClick = switchActiveToolbarItem;
    if(stage==='settings-list'){
        onBackButtonClick = ()=> setStage('menu');
    }

    let componentClassName = 'privacy-settings-item toolbar-item';
    if(activeToolbarMenuItem === CSM_MENU_ITEM_PRIVACY_SETTINGS){
        componentClassName += ' active';
    }
    return (
        <div className={componentClassName} >
            <ToolbarItem
                itemId='csm-privacy-settings'
                image="images/settings-icon.png"
                onBackButtonClick={onBackButtonClick}
                title="Privacy Settings"
                showModal={showModal}
                modalContent={modalContent}
                setShowModal={setShowModal}
                >
                {stage==='menu' && <PrivacyMenu 
                    setPrivacyStage={setStage}
                    onSuppressInformation ={onSuppressInformation}
                    onDeleteInformation = {onDeleteInformation}
                    showPersonasRegulationButtons = {showPersonasRegulationButtons}
                />}
                {stage==='settings-list' && <PrivacySettingsList privacyPreferences={privacyPreferences} onTogglePreferences={onTogglePreferences}/>}
            </ToolbarItem>
        </div>
    )
}

export default withToolbarSwitcher()(withNotify()(withSpinner('100px', '100px', '50px')(observer(PrivacySettingsItem))))
