import { makeAutoObservable } from "mobx";
import * as config from '../config'
import Cookies from "universal-cookie";

export default class PrivacyPolicyStore{
    
    constructor(rootStore){
        this.rootStore = rootStore;

        this._customerPrivacyPolicyStatus = 'hidden';
        this._previousCustomerPrivacyPolicyStatus = '';
        this._customerPrivacyPolicyPreferences = [];

        makeAutoObservable(this);
    }

    get privacyPolicyStatus(){
        return this._customerPrivacyPolicyStatus;
    }

    get previousCustomerPrivacyPolicyStatus(){
        return this._previousCustomerPrivacyPolicyStatus;
    }

    get customerPrivacyPolicyPreferences(){
        return this._customerPrivacyPolicyPreferences;
    }

    setCustomerPrivacyPolicyPreferences(preferences){
        this._customerPrivacyPolicyPreferences = preferences;
    }

    setPrivacyPolicyStatus(status){
        this._previousCustomerPrivacyPolicyStatus = this._customerPrivacyPolicyStatus;
        this._customerPrivacyPolicyStatus = status;

        let date = new Date();
        date.setTime(date.getTime() + (config.COOKIE_POLICY_EXPIRE_DAYS * 24 * 60 * 60 * 1000));

        const cookies = new Cookies();
        cookies.set('CookiePolicy',status,{expires: date});
    }

    hideCookiesPolicy(toolbarExpanded = false){
        console.log('new expanded', toolbarExpanded)
        console.log(this)
        this.setPrivacyPolicyStatus('hidden');
        this.rootStore.uiStore.switchToolbar(toolbarExpanded);
        this.rootStore.uiStore.setToolbarOpened(toolbarExpanded)
    }

    setConsentManagerPreferences(customPreferences){
        const consentPreferences = window.consentManager.preferences.loadPreferences();
        consentPreferences.customPreferences = {};
        console.log('custom pref',customPreferences);
        customPreferences.forEach(preference => {
          consentPreferences.customPreferences[preference.id] = preference.value
        });
    
        const destinationPreferences = {
          "Facebook Pixel": customPreferences.find(preference => preference.id === 'advertising').value,
          "Google Analytics": customPreferences.find(preference => preference.id === 'marketingAndAnalytics').value, 
          "Google Tag Manager": customPreferences.find(preference => preference.id === 'advertising').value,
        }
    
        consentPreferences.destinationPreferences = destinationPreferences;
        window.consentManager.preferences.savePreferences(consentPreferences);

        this.setCustomerPrivacyPolicyPreferences(customPreferences);
    }
}