import React, { useState, useContext, useEffect } from 'react';
import { withToolbarSwitcher } from '../../../hoc-helpers'
import { observer } from 'mobx-react-lite';
import ToolbarItem from './../../../cc-components/toolbar-item';
import ActivationInfo from '../activation-info';
import ActivationSearch from '../activation-search';
import SearchResults from '../search-results';
import { animateScroll } from "react-scroll";
import './activate-profile-item.css';
import CustomerActivation from '../customer-activation';
import {
    ACTIVATION_STAGE_ACTIVATION,
    ACTIVATION_STAGE_INFO,
    ACTIVATION_STAGE_SEARCH,
    ACTIVATION_STAGE_SEARCH_RESULTS,
    ACTIVATION_STAGE_ACTIVATION_SUCCESS
} from '../../../../utils/activation-stages';
import { AppContext } from '../../../../context';
import ActivationSuccess from '../activation-success/activation-success';
import { OPEN_CSM_ACTIVATION_PROFILE_CLASS } from '../../../../utils/csm-interactive';
import {CSM_MENU_ITEM_ACTIVATE_PROFILE} from '../../../../utils/csm-menu-items'

function ActivateProfileItem({
    activeToolbarMenuItem, 
    switchActiveToolbarItem,
}) {

    const {customerStore, uiStore, privacyPolicyStore} = useContext(AppContext)

    const [activationStage, setActivationStage] = useState(ACTIVATION_STAGE_INFO)
    const [referrerActivationStage, setReferrerActivationStage] = useState(ACTIVATION_STAGE_INFO)
    const [searchCustomers, setSearchCustomers] = useState([])
    const [activationType, setActivationType] = useState('existing')
    const [customer, setCustomer] = useState({})

    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState(null)

    useEffect(() => {
      
        document.addEventListener('click',function(e){
            if(e.target && e.target.classList.contains(OPEN_CSM_ACTIVATION_PROFILE_CLASS)){
                    e.preventDefault()
                    if(privacyPolicyStore.privacyPolicyStatus === 'hidden'){
                        uiStore.setToolbarOpened(true);
                        if(customerStore.customerData.userId != null){
                            switchActiveToolbarItem(null);
                        }
                        else{
                            if(activeToolbarMenuItem !== CSM_MENU_ITEM_ACTIVATE_PROFILE)
                                switchActiveToolbarItem(CSM_MENU_ITEM_ACTIVATE_PROFILE);

                        }
                    }
                    else if(privacyPolicyStore.privacyPolicyStatus === 'declined'){
                        privacyPolicyStore.setPrivacyPolicyStatus(undefined);
                    }
                    else{
                        uiStore.shakeCookiesContainer();
                    }
                }
            })
    }, [])
    

    const onCustomerSearchSuccess = (customers) => {
        setSearchCustomers(customers);
        onActivationStageChanged(ACTIVATION_STAGE_SEARCH_RESULTS);
    }

    const onCustomerClick = (id) => {
        const customer = searchCustomers.find(c => c.id === id);
        if(customer){
            setCustomer(customer);
            onActivationStageChanged(ACTIVATION_STAGE_ACTIVATION)
        }
    }

    const onActivationSuccess = (customerData) => {
        console.log(customerData)
        customerStore.authorizeCustomer(customerData);
        onActivationStageChanged(ACTIVATION_STAGE_ACTIVATION_SUCCESS)
        
        const authEvent = new CustomEvent("user-login", {detail: {user_id: customerData.userId, class_of_trade: customerData.class_of_trade}});
        document.dispatchEvent(authEvent);
    }

    const onActivationStageChanged = (stage) => {
        setReferrerActivationStage(activationStage);
        setActivationStage(stage);
        animateScroll.scrollToTop({
            containerId: "csm-activate-profile",
            duration: 50
        });
    }

    const onNpiRegistryClick = () => {
        setShowModal(true)
        const content = <div className='csm-modal-details'>
                            <p className='font-bold'>Now you will be redirected on another site in new tab</p>
                            <a href='https://npiregistry.cms.hhs.gov/' target='_blank' rel="noreferrer" className="std-button" onClick={() => setShowModal(false)}>Go to NPI Registry</a>
                        </div>
        setModalContent(content)
    }
     

    const onCreateNewProfileClick = () => {
        setActivationType('new');
        onActivationStageChanged(ACTIVATION_STAGE_SEARCH);
        
        // console.log('on click')
    }

    const onCreateProfileManually = () => {
        setActivationType('new');
        onActivationStageChanged(ACTIVATION_STAGE_ACTIVATION);
        setCustomer(null);
     }

    let onBackButtonClick = switchActiveToolbarItem;
    if(activationStage === ACTIVATION_STAGE_SEARCH){
        onBackButtonClick = () => onActivationStageChanged(ACTIVATION_STAGE_INFO);
    }
    if(activationStage === ACTIVATION_STAGE_SEARCH_RESULTS){
        onBackButtonClick = () => onActivationStageChanged(ACTIVATION_STAGE_SEARCH);
    }
    if(activationStage === ACTIVATION_STAGE_ACTIVATION && customer === null){
        onBackButtonClick = () => onActivationStageChanged(ACTIVATION_STAGE_INFO);
    }
    else if(activationStage === ACTIVATION_STAGE_ACTIVATION){
        onBackButtonClick = () => onActivationStageChanged(ACTIVATION_STAGE_SEARCH_RESULTS);
    }

    let componentClassName = 'activate-profile-item toolbar-item';
    if(activeToolbarMenuItem === CSM_MENU_ITEM_ACTIVATE_PROFILE){
        componentClassName += ' active';
    }
    
    return (
        <div id='activate-profile' className={componentClassName}>
            <ToolbarItem itemId='csm-activate-profile' image="images/customer-profile-icon.png" onBackButtonClick={onBackButtonClick} title={'Activate your profile'} showModal={showModal} modalContent={modalContent} setShowModal={setShowModal}>
                <ActivationInfo 
                    activationStage={activationStage}
                    setActivationStage={onActivationStageChanged}
                    setActivationType={setActivationType}
                    setReferrerActivationStage={setReferrerActivationStage}/>

                <ActivationSearch
                    activationStage={activationStage}
                    activationType={activationType}
                    onCustomerSearchSuccess={onCustomerSearchSuccess}
                    onNpiRegistryClick={onNpiRegistryClick}
                    onCreateNewProfileClick={onCreateNewProfileClick}
                    onCreateProfileManually={onCreateProfileManually}/>

                <SearchResults 
                    activationStage={activationStage}
                    activationType={activationType}
                    setActivationStage={onActivationStageChanged} 
                    onCustomerClick={onCustomerClick} 
                    customers={searchCustomers}
                    onCreateNewProfileClick={onCreateNewProfileClick}
                    onCreateProfileManually={onCreateProfileManually}/>

                {activationStage==='activation' &&
                    <CustomerActivation 
                        activationStage={activationStage}
                        setActivationStage={onActivationStageChanged}
                        onActivationSuccess={onActivationSuccess}
                        customer={customer}
                        activationType={activationType}
                        setReferrerActivationStage={setReferrerActivationStage}
                        referrerActivationStage={referrerActivationStage}/>}

                <ActivationSuccess 
                    activationStage={activationStage}
                    activationType={activationType}
                    onMenuButtonClick={onBackButtonClick}/>
            </ToolbarItem>
        </div>
    )
}

export default withToolbarSwitcher()(observer(ActivateProfileItem))
