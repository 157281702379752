import React, {useContext} from 'react'
import * as config from '../../../config';
import { AppContext } from '../../../context';
import PrcFooter from '../prc-footer';

function PrivacyMenu({
    setPrivacyStage,
    onSuppressInformation,
    onDeleteInformation,
    showPersonasRegulationButtons
}) {

    const{customerStore} = useContext(AppContext);

    return (
        <div className='privacy-menu stage-body body-with-prc-footer'>
            <div className='privacy-title font-bold'>Would you like to review your Privacy Settings{`${customerStore.customerData.userId != null ? ` ${customerStore.customerData.first_name} ${customerStore.customerData.last_name}`  : ''}?`} </div>
            <button className="tb-menu-item font-bold" onClick={() => setPrivacyStage('settings-list')}>
                <span className='tb-menu-icon-wrapper'> 
                    <img className="tb-menu-icon" src={`${config.IMG_HOSTING}/images/settings-icon.svg`} alt="settings"></img>
                </span>                       
                <span className="tb-item-text">Manage Privacy Settings</span>
            </button>
            <button className={`tb-menu-item font-bold ${!showPersonasRegulationButtons && 'tb-hidden'}`} onClick={onSuppressInformation}>
                <span className='tb-menu-icon-wrapper'> 
                    <img className="tb-menu-icon" src={`${config.IMG_HOSTING}/images/suppress-icon.svg`} alt="suppress"></img>
                </span>                       
                <span className="tb-item-text">Suppress information capture</span>
            </button>
            <button className={`tb-menu-item font-bold ${!showPersonasRegulationButtons && 'tb-hidden'}`} onClick={onDeleteInformation}>
                <span className='tb-menu-icon-wrapper'> 
                    <img className="tb-menu-icon" src={`${config.IMG_HOSTING}/images/delete-icon.svg`} alt="delete"></img>
                </span>                       
                <span className="tb-item-text">Delete my information</span>
            </button>
            <PrcFooter absolute={true}/>
        </div>
    )
}

export default PrivacyMenu
