import React, {useContext}from 'react'
import ChatMessage from '../chat-message'
import * as config from '../../../config'
import VoiceCall from './../voice-call';
import {AppContext} from './../../../context';
import './message-list.css'
import { observer } from 'mobx-react-lite';

function MessageList({
    messages,
    isCalling,
    onVoiceCallEnd
}) {

    const{uiStore, chatStore} = useContext(AppContext);

    const onMultiplySelectMenuClose = () => {
        console.log('multy click')
    }

    const elements = chatStore.messages.map((message, index) => {
        const { sid, onButtonClick, isUserMsg = false, showAvatar = true } = message;
        const authorClass = isUserMsg ? 'user' : 'bot';
        let containerClasses = `chat-message-container message-${authorClass}`;
        if(!showAvatar && !isUserMsg){
            containerClasses += ' msg-queue';
        }
        const msgIcon = <div className="msg-icon">
                <img  src={`${config.IMG_HOSTING}/images/agent-avatar-icon.svg`} alt="close"></img>
            </div>

        return (
            <div key={sid} className={containerClasses}>
                {!isUserMsg && showAvatar? msgIcon : null}
                <ChatMessage message={message} onButtonClick={onButtonClick} onMultiplySelectMenuClose={onMultiplySelectMenuClose}/>
                {/* <MessageTime messageDate={messageDate}/> */}                
            </div>
        )
    })

    return (
        <div className="message-list">
            {elements}
            {isCalling && <VoiceCall onVoiceCallEnd={onVoiceCallEnd}/>}
        </div>
    )
}

export default observer(MessageList)
